import { useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';

function useSingleSomethingUpdatesA10({
  variables,
  subscription,
  createAction,
  updateAction,
  deleteAction,
  extractSomething,
  queryId = 'default',
  skip,
}) {
  const dispatch = useDispatch();
  const result = useSubscription(subscription, {
    skip,
    variables,
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: resp }) => {
      try {
        const update = extractSomething(resp);
        if (update) {
          switch (update.crud) {
            case 'create':
              dispatch(createAction(update.new, queryId));
              break;
            case 'read':
              dispatch(createAction(update.new, queryId));
              break;
            case 'update':
              dispatch(updateAction(update.new, queryId));
              break;
            case 'delete':
              dispatch(deleteAction(update.id, queryId));
              break;
            default:
              break;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  return result;
}

export default useSingleSomethingUpdatesA10;
