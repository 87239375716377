import { gql, useQuery } from '@apollo/client';
import { Select } from 'antd';
import React, { useMemo } from 'react';
import useProfile from '../../../shared/use-profile';

export const allJPRegionsQuery = gql`
  query AllJPRegions {
    allJPRegions {
      _id
      name
      continentCode
    }
  }
`;

function SelectRegionForAdmin({ disabled, value, onChange, style, ...props }) {
  const profile = useProfile();
  const { loading, error, data } = useQuery(allJPRegionsQuery);
  const regions = data?.allJPRegions;
  const options = useMemo(() => {
    if (regions) {
      return regions
        .filter((r) => {
          if (profile.roleLevel === 2 && r._id !== profile.regionCode) {
            return false;
          }
          return true;
        })
        .map((item) => {
          return {
            value: item._id,
            label: item.name,
          };
        });
    }
    return [];
  }, [regions, profile]);
  return (
    <Select
      style={{ width: '100%', ...style }}
      placeholder="Select a region"
      onChange={onChange}
      value={value}
      options={options}
      allowClear
      filterOption="label"
      showSearch
      loading={loading}
      disabled={disabled}
      {...props}
    />
  );
}

export default SelectRegionForAdmin;
