import { useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Form,
  InputNumber,
  Modal,
  notification,
  Typography,
} from 'antd';
import React, { useCallback, useState } from 'react';
import { initiateHolisticProjectAccountingMutation } from './constants';

const { Paragraph, Title } = Typography;

function InitiateAccountingModal({ project, showingModal, setShowingModal }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [initiate] = useMutation(initiateHolisticProjectAccountingMutation);

  const handleFinish = useCallback(
    async (values) => {
      setError(undefined);
      setLoading(true);
      try {
        await initiate({
          variables: {
            _id: project._id,
            projectNo: values.projectNo || undefined,
          },
        });
        notification.success({
          message: 'Success',
          description: 'Holistic project accounting initiated',
        });
        setShowingModal(false);
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred',
        });
      }
      setLoading(false);
    },
    [initiate, project, setShowingModal],
  );

  return (
    <Modal
      header={null}
      footer={null}
      open={showingModal}
      closable={!loading}
      destroyOnClose={true}
      maskClosable={!loading}
      onCancel={() => {
        setShowingModal(false);
      }}
      styles={{ body: { paddingTop: 48 } }}
    >
      <Title level={3} style={{ textAlign: 'center', marginBottom: 32 }}>
        Initiate Holistic Project Accounting
      </Title>
      <Form onFinish={handleFinish}>
        {project.projectName && (
          <Form.Item>
            <Paragraph style={{ textAlign: 'center', marginBottom: 0 }}>
              {`This action will assign a project number, calculate the total total funding target, and create funding and payback accounts for the holistic project ${project.projectName}.`}
            </Paragraph>
          </Form.Item>
        )}
        <Form.Item name="hasProjectNo" valuePropName="checked" noStyle>
          <div style={{ textAlign: 'center', marginBottom: 16 }}>
            <Checkbox>This project already has a project #</Checkbox>
          </div>
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.hasProjectNo !== curValues.hasProjectNo
          }
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue('hasProjectNo') && (
              <Form.Item
                label="Project #"
                name="projectNo"
                rules={[{ required: true, message: 'Please enter a number' }]}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <InputNumber disable={loading} min={0} step={1} />
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item>
          <Paragraph style={{ textAlign: 'center', marginBottom: 0 }}>
            {`Do you want to continue?`}
          </Paragraph>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <div
            style={{
              marginRight: -8,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => setShowingModal(null)}
              htmlType="button"
              disabled={loading}
              style={{
                marginLeft: 8,
                marginRight: 8,
                marginBottom: 8,
                minWidth: 100,
              }}
            >
              No
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
              style={{
                marginLeft: 8,
                marginRight: 8,
                marginBottom: 8,
                minWidth: 100,
              }}
            >
              Yes
            </Button>
          </div>
        </Form.Item>
      </Form>
      {error && (
        <Paragraph type="danger" style={{ textAlign: 'center', marginTop: 16 }}>
          {error}
        </Paragraph>
      )}
    </Modal>
  );
}

export default InitiateAccountingModal;
