import { Table, Typography } from 'antd';
import React from 'react';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { shallowEqual, useSelector } from 'react-redux';

const { Title, Text, Paragraph } = Typography;

function CumulativeProjectStats({ holisticProject }) {
  const loanTotal = getNumberValue(holisticProject.loanTotal);
  const loanTerm = getNumberValue(holisticProject.loanTerm);
  const locale = useSelector((store) => store.locale, shallowEqual);
  const loanStartDate =
    holisticProject.loanStartDate &&
    new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
    }).format(new Date(holisticProject.loanStartDate));
  let hasReceivedFunds;
  let totalPayments;
  let loanBalance = loanTotal;
  let revenueForeign;
  let expensesForeign;
  let latestExchangeRate;
  let latestCurrency;
  let revenueUsd;
  let expensesUsd;
  let toSupportedFieldWorkersForeign;
  let toSupportedFieldWorkersUsd;
  let netProfitForeign;
  let netProfitUsd;
  let numProgressReports = 0;
  const { cumulativeStats } = holisticProject;
  if (cumulativeStats) {
    numProgressReports = getNumberValue(cumulativeStats.numProgressReports);
    hasReceivedFunds = cumulativeStats.hasReceivedFunds;
    totalPayments = getNumberValue(cumulativeStats.loanPaymentsMade);

    loanBalance = loanTotal - totalPayments;
    revenueForeign = cumulativeStats.cumulativeRevenue;
    expensesForeign = cumulativeStats.cumulativeExpenses;
    toSupportedFieldWorkersForeign =
      holisticProject.supportedFieldWorkers &&
      holisticProject.supportedFieldWorkers.reduce((prev, curr) => {
        return prev + getNumberValue(curr.totalReceived);
      }, 0);
    netProfitForeign =
      revenueForeign - expensesForeign - toSupportedFieldWorkersForeign;
    latestExchangeRate = getNumberValue(cumulativeStats.latestExchangeRate);
    latestCurrency = cumulativeStats.latestCurrency;
    if (latestExchangeRate > 0) {
      revenueUsd = revenueForeign / latestExchangeRate;
      expensesUsd = expensesForeign / latestExchangeRate;
      toSupportedFieldWorkersUsd =
        toSupportedFieldWorkersForeign / latestExchangeRate;
      netProfitUsd = revenueUsd - expensesUsd - toSupportedFieldWorkersUsd;
    }
  }

  return (
    <>
      <Title level={3}>Cumulative Project Stats</Title>
      <Paragraph style={{ textAlign: 'center', fontSize: 14 }}>
        {`These stats are based on the ${numProgressReports} quarterly progress reports submitted and
        approved so far.`}
      </Paragraph>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            fontSize: 16,
            textAlign: 'left',
            marginBottom: 16,
            marginRight: 32,
          }}
        >
          <div>{`Loan Amount: ${displayMoney(loanTotal)}`}</div>
          <div>{`Loan Term: ${loanTerm} Months`}</div>
          {loanStartDate ? (
            <div>{`Loan Start Date: ${loanStartDate}`}</div>
          ) : (
            <div>{`No Start Date`}</div>
          )}
        </div>
        <div
          style={{
            fontSize: 16,
            textAlign: 'left',
            marginBottom: 16,
          }}
        >
          <div>{`Funds Received: ${hasReceivedFunds ? 'Yes' : 'No'}`}</div>
          <div>{`Total Payments: ${displayMoney(
            getNumberValue(totalPayments),
          )}`}</div>
          <div>{`Loan Balance: ${displayMoney(
            getNumberValue(loanBalance),
          )}`}</div>
        </div>
      </div>
      {latestExchangeRate && latestCurrency && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontSize: 16,
              textAlign: 'left',
              marginBottom: 16,
            }}
          >
            <div>{`Latest Exchange Rate: $1 = ${latestExchangeRate} ${latestCurrency}`}</div>
          </div>
        </div>
      )}
      <div style={{ height: 16 }} />
      <Table
        bordered
        dataSource={[
          {
            row: 'A',
            label: 'Revenue So Far',
            value: getNumberValue(revenueForeign),
            usd: displayMoney(getNumberValue(revenueUsd)),
            color: undefined,
          },
          {
            row: 'B',
            label: 'Expenses So Far',
            value: getNumberValue(expensesForeign),
            usd: displayMoney(getNumberValue(expensesUsd)),
            color: undefined,
          },
          {
            row: 'C',
            label: 'Field Worker Support So Far',
            value: getNumberValue(toSupportedFieldWorkersForeign),
            usd: displayMoney(getNumberValue(toSupportedFieldWorkersUsd)),
            color: undefined,
          },
          {
            row: 'D',
            label: 'Net Profit So Far (A - B - C)',
            value: getNumberValue(netProfitForeign),
            usd: displayMoney(getNumberValue(netProfitUsd)),
            color: undefined,
          },
        ]}
        showHeader
        rowKey="row"
        pagination={false}
      >
        <Table.Column
          dataIndex="row"
          key="row"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'nowrap' }}>
              {text}
            </Text>
          )}
        />
        <Table.Column
          dataIndex="label"
          key="label"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'nowrap' }}>
              {text}
            </Text>
          )}
        />
        <Table.Column
          title="Local Currency"
          dataIndex="value"
          key="value"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'nowrap' }}>
              {text}
            </Text>
          )}
        />
        <Table.Column
          title="USD"
          dataIndex="usd"
          key="usd"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'nowrap' }}>
              {text}
            </Text>
          )}
        />
      </Table>
    </>
  );
}

export default CumulativeProjectStats;
