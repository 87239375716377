import useSafeStateA10 from '@aims/shared/shared/use-safe-state-a10';
import { useApolloClient } from '@apollo/client';
import { Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { adoptionForAdminQuery } from '../../adoptions/constants';
import { ppPayerQuery } from '../payers/constants';
import { ppRecurringPaymentQuery } from '../recurring-payments/constants';
import AdmContactPageA10 from './AdmContactPageA10';
import AdmInitPageA10 from './AdmInitPageA10';
import AdmRecurringQuestionPageA10 from './AdmRecurringQuestionPageA10';
import AdmSponsorshipPageA10 from './AdmSponsorshipPageA10';
import AdmSummaryPageA10 from './AdmSummaryPageA10';

function AssociateDonationsModalA10({ visible, onCancel, onDone }) {
  const { payment, isRecurring } = visible || {};
  const [page, setPage] = useState('init');
  const [contact, setContact] = useState();
  const [recurringPayment, setRecurringPayment] = useState();
  const [payer, setPayer] = useState();
  const client = useApolloClient();
  const [adoptionItems, setAdoptionItems, _adoptionItems] = useSafeStateA10([]);

  const onAddAdoption = useCallback(
    (adoption) => {
      const percent = 1.0 / (_adoptionItems.current.length + 1);
      setAdoptionItems([
        ..._adoptionItems.current.map((a) => ({
          ...a,
          percent,
          usdCentsAmount: payment.usdCentsAmount * percent,
        })),
        {
          _id: adoption._id,
          adoption,
          percent,
          usdCentsAmount: payment.usdCentsAmount * percent,
          edited: false,
        },
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [payment],
  );
  const onRemoveAdoption = (record) => {
    setAdoptionItems(adoptionItems.filter((a) => a._id !== record._id));
  };
  const onAddAccount = useCallback(
    (account) => {
      const percent = 1.0 / (_adoptionItems.current.length + 1);
      setAdoptionItems([
        ..._adoptionItems.current.map((a) => ({
          ...a,
          percent,
          usdCentsAmount: payment.usdCentsAmount * percent,
        })),
        {
          _id: account._id,
          account,
          percent,
          usdCentsAmount: payment.usdCentsAmount * percent,
          edited: false,
        },
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [payment],
  );

  useEffect(() => {
    async function doAsyncStuff() {
      let resp = await client.query({
        query: ppPayerQuery,
        variables: {
          _id: payment.payerId,
        },
      });
      if (resp?.data?.ppPayerForAdmin) {
        setPayer(resp.data.ppPayerForAdmin);
      }
      if (payment.adoptions && payment.adoptions.length > 0) {
        await Promise.all(
          payment.adoptions.map(async (a) => {
            const resp2 = await client.query({
              query: adoptionForAdminQuery,
              variables: {
                _id: a.adoptionId,
              },
            });
            const adoption = resp2?.data?.adoptionForAdmin;
            if (adoption) {
              onAddAdoption(adoption);
            }
          }),
        );
      }
      if (!isRecurring && payment.recurringPaymentToken) {
        resp = await client.query({
          query: ppRecurringPaymentQuery,
          variables: {
            _id: payment.recurringPaymentToken,
          },
        });
        const recurring = resp?.data?.ppRecurringPaymentForAdmin;
        if (recurring) {
          setRecurringPayment(recurring);
          if (recurring.adoptions && recurring.adoptions.length > 0) {
            await Promise.all(
              recurring.adoptions.map(async (a) => {
                const resp2 = await client.query({
                  query: adoptionForAdminQuery,
                  variables: {
                    _id: a.adoptionId,
                  },
                });
                const adoption = resp2?.data?.adoptionForAdmin;
                if (adoption) {
                  onAddAdoption(adoption);
                }
              }),
            );
          }
        }
      } else if (payment.suggestedAllocations?.length > 0) {
        await Promise.all(
          payment.suggestedAllocations.map(async (a) => {
            const resp2 = await client.query({
              query: adoptionForAdminQuery,
              variables: {
                _id: a.adoptionId,
              },
            });
            const adoption = resp2?.data?.adoptionForAdmin;
            if (adoption) {
              onAddAdoption(adoption);
            }
          }),
        );
      }
      setPage('contact');
    }
    if (payment) {
      setAdoptionItems([]);
      doAsyncStuff();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, payment, isRecurring, onAddAdoption]);

  return (
    <Modal
      open={visible}
      closable={false}
      destroyOnClose={true}
      maskClosable
      cancelText="Cancel"
      okText="Change Password"
      footer={null}
      onCancel={onCancel}
      width={700}
      styles={{
        body: {
          paddingTop: 32,
        },
      }}
    >
      {page === 'init' && <AdmInitPageA10 />}
      {page === 'contact' && (
        <AdmContactPageA10
          payment={payment}
          payer={payer}
          isRecurring={isRecurring}
          recurringPayment={recurringPayment}
          contact={contact}
          setContact={setContact}
          setPage={setPage}
          adoptionItems={adoptionItems}
        />
      )}
      {page === 'existing' && (
        <AdmRecurringQuestionPageA10
          payment={payment}
          payer={payer}
          recurringPayment={recurringPayment}
          setPage={setPage}
          adoptionItems={adoptionItems}
          onAddAdoption={onAddAdoption}
          onRemoveAdoption={onRemoveAdoption}
        />
      )}
      {page === 'sponsorship' && (
        <AdmSponsorshipPageA10
          payment={payment}
          payer={payer}
          isRecurring={isRecurring}
          recurringPayment={recurringPayment}
          setPage={setPage}
          adoptionItems={adoptionItems}
          onAddAdoption={onAddAdoption}
        />
      )}
      {page === 'summary' && (
        <AdmSummaryPageA10
          payment={payment}
          payer={payer}
          recurringPayment={recurringPayment}
          isRecurring={isRecurring}
          setPage={setPage}
          adoptionItems={adoptionItems}
          onRemoveAdoption={onRemoveAdoption}
          onAddAccount={onAddAccount}
          onCancel={onCancel}
          contact={contact}
          onDone={onDone}
        />
      )}
    </Modal>
  );
}

export default AssociateDonationsModalA10;
