import {
  BarChartOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const { Title } = Typography;

function AccountsSelectToolbar({
  selected,
  hideUnselected,
  setSelected,
  setHideUnselected,
}) {
  return (
    <>
      {((selected && selected.length > 0) || hideUnselected) && (
        <div
          style={{
            display: 'flex',
            backgroundColor: '#c6cfd3',
            marginBottom: 16,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 12,
            paddingBottom: 12,
            alignItems: 'center',
            borderRadius: 8,
          }}
        >
          <Title level={5} style={{ margin: 0, marginRight: 16 }}>
            {`${selected.length} Accounts Selected`}
          </Title>
          <Button
            icon={<SelectOutlined />}
            type="dashed"
            onClick={() => {
              setSelected([]);
              setHideUnselected(false);
            }}
            style={{ marginLeft: 'auto', marginRight: 16 }}
            shape="round"
          >
            Unselected All
          </Button>

          {hideUnselected ? (
            <Button
              icon={<EyeOutlined />}
              type="dashed"
              onClick={() => setHideUnselected(false)}
              style={{ marginRight: 16 }}
              shape="round"
            >
              Show Unselected
            </Button>
          ) : (
            <Button
              icon={<EyeInvisibleOutlined />}
              type="dashed"
              onClick={() => setHideUnselected(true)}
              style={{ marginRight: 16 }}
              shape="round"
            >
              Hide Unselected
            </Button>
          )}
          <Link to={`/accounting/reporting`}>
            <Button icon={<BarChartOutlined />} type="primary" shape="round">
              Generate Report
            </Button>
          </Link>
        </div>
      )}
    </>
  );
}

export default AccountsSelectToolbar;
