import { gql } from '@apollo/client';

export const holisticProjectDetailedAttributes = gql`
  {
    _id
    projectNo
    showInCatalog
    applicationId
    regionCode
    region
    regionalCoordId
    regionalCoordinator {
      _id
      fullName
      contactType
      primaryThumbnailUrl
      primaryEmailId
      primaryPhoneNumberId
      phoneNumbers {
        _id
        label
        phone
      }
      emails {
        _id
        label
        email
      }
    }
    peopleGroupIds
    peopleGroups {
      _id
      population
      continentCode
      continent
      region
      regionCode
      countryCode
      country
      nameAcrossCountries
      primaryLanguage
      officialLanguage
      primaryReligion
      religionSubdivision
      primaryThumbnailUrl
      updatedAt
    }
    projectName
    typeOfProject
    desc
    stage
    loanTotal
    loanTerm
    loanStartDate
    supportedFieldWorkers {
      contactId
      contact {
        _id
        fullName
        contactType
        primaryThumbnailUrl
        primaryEmailId
        primaryPhoneNumberId
        phoneNumbers {
          _id
          label
          phone
        }
        emails {
          _id
          label
          email
        }
      }
      totalReceived
    }
    numSupported
    responsiblePersons {
      contactId
      contact {
        _id
        fullName
        contactType
        primaryThumbnailUrl
        primaryEmailId
        primaryPhoneNumberId
        phoneNumbers {
          _id
          label
          phone
        }
        emails {
          _id
          label
          email
        }
      }
      role
    }
    tags {
      key
      color
    }
    adminNotes
    photoIds
    primaryPhotoId
    primaryThumbnailUrl
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
    cumulativeStats {
      numMiraclesReported
      hasReceivedFunds
      loanPaymentsMade
      cumulativeExpenses
      cumulativeRevenue
      latestExchangeRate
      latestCurrency
      numProgressReports
    }
  }
`;

export const holisticProjectListAttributes = gql`
  {
    _id
    regionCode
    regionalCoordId
    regionalCoordinator {
      _id
      fullName
      contactType
    }
    peopleGroupIds
    peopleGroups {
      _id
      nameAcrossCountries
      country
    }
    projectName
    typeOfProject
    desc
    stage
    loanTotal
    primaryThumbnailUrl
    tags {
      key
      color
    }
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
    projectNo
    showInCatalog
  }
`;

export const holisticProjectPhotosAttributes = gql`
  {
    _id
    applicationId
    projectName
    typeOfProject
    desc
    stage
    primaryThumbnailUrl
    primaryPhotoId
    photoIds
    photos {
      _id
      desc
      s3Key
      size
      filename
      contentType
      tags
      url
      uploaded
      processed
      thumbnail {
        s3Key
        size
        filename
        contentType
        url
        height
        width
        sizeCode
      }
      images {
        s3Key
        size
        filename
        contentType
        url
        height
        width
        sizeCode
      }
      attachedTo
      profilePhotoFor
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
    deletedAt
  }
`;

export const createHolisticProjectMutation = gql`
  mutation createHolisticProject(
    $holisticProject: HolisticProjectCreateInput!
  ) {
    createHolisticProject(holisticProject: $holisticProject) {
      holisticProject {
        _id
      }
    }
  }
`;

export const updateHolisticProjectMutation = gql`
  mutation updateHolisticProject(
    $holisticProject: HolisticProjectUpdateInput!
  ) {
    updateHolisticProject(holisticProject: $holisticProject) {
      holisticProject {
        _id
      }
    }
  }
`;

export const updateHolisticProjectPhotosMutation = gql`
  mutation UpdateHolisticProjectPhotos(
    $holisticProject: HolisticProjectUpdateInput!
  ) {
    updateHolisticProject(holisticProject: $holisticProject) {
      holisticProject {
        _id
      }
    }
  }
`;

export const allHolisticProjectsForAdminQuery = gql`
  query AllHolisticProjectsForAdmin(
    $first: Int
    $after: String
    $sortBy: [SortBy]
    $filters: HolisticProjectFiltersForAdmin
  ) {
    allHolisticProjectsForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${holisticProjectListAttributes}
      }
    }
  }
`;

export const extractHolisticProjectsForAdmin = (data) =>
  data.allHolisticProjectsForAdmin;

export const holisticProjectForAdminQuery = gql`
  query HolisticProjectForAdmin($_id: ID!) {
    holisticProjectForAdmin(_id: $_id) ${holisticProjectDetailedAttributes}
  }
`;

export const extractHolisticProjectForAdmin = (data) =>
  data && data.holisticProjectForAdmin;

export const holisticProjectPhotosQuery = gql`
  query HolisticProjectPhotos($_id: ID!) {
    holisticProjectForAdmin(_id: $_id) ${holisticProjectPhotosAttributes}
  }
`;

export const deleteHolisticProgressReportMutation = gql`
  mutation DeleteHolisticProgressReport($reportId: ID!) {
    deleteHolisticProgressReport(reportId: $reportId) {
      deletedId
    }
  }
`;

export const HolisticProjectUpdateAttributes = gql`
{
  crud
  id
  new ${holisticProjectDetailedAttributes}
}
`;

export const holisticProjectUpdatesForAdminSubscription = gql`
  subscription HolisticProjectUpdatesForAdmin($_id: ID!) {
    holisticProjectUpdatesForAdmin(_id: $_id) ${HolisticProjectUpdateAttributes}
  }
`;
