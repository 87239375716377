/* eslint-disable react/display-name */
import sharedSettings from '@aims/shared/sharedSettings';
import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip, Typography } from 'antd';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ActivityTypeIcons } from '../../pages/activity/EditActivity/constants';
import useProfile from '../../shared/use-profile';
import DisplayAttachments from '../AnyAttachments/DisplayAttachments';
import AssigneeDot from '../AssigneeDot';
import ContentEditableInput from '../ContentEditableInput';

const { Paragraph } = Typography;

function ActivityCard({ activity, setShowActivityModal }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const profile = useProfile();
  return (
    <Card>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {activity.createdByUser && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 16,
                }}
              >
                <AssigneeDot _id={activity.createdByUser._id} />
                <div style={{ marginLeft: 16 }}>
                  {activity.createdByUser.name}
                </div>
              </div>
            )}
            <div style={{ color: sharedSettings.colors.textGray }}>
              {activity.when &&
                `${new Intl.DateTimeFormat(locale, {
                  dateStyle: 'full',
                }).format(new Date(activity.when))} @ ${new Intl.DateTimeFormat(
                  locale,
                  {
                    timeStyle: 'short',
                  },
                ).format(new Date(activity.when))}`}
            </div>
          </div>
          {activity.createdBy === `user:${profile._id}` && (
            <div style={{ marginLeft: 'auto' }}>
              <Tooltip title="Edit">
                <Button
                  onClick={() => setShowActivityModal(activity._id)}
                  icon={<EditOutlined />}
                  type="text"
                />
              </Tooltip>
            </div>
          )}
        </div>
        <div style={{ display: 'flex', marginBottom: 12 }}>
          <div style={{ fontSize: 32, marginRight: 16 }}>
            {ActivityTypeIcons[activity.type].icon}
          </div>
          <div>
            <Paragraph style={{ fontSize: 16, marginBottom: 0 }}>
              {ActivityTypeIcons[activity.type].label}
            </Paragraph>
            {activity.desc && (
              <ContentEditableInput readOnly value={activity.desc} />
            )}
          </div>
        </div>
        <DisplayAttachments attachments={activity.attachments} />
      </div>
    </Card>
  );
}

export default ActivityCard;
