import { HolisticProjectStages } from '@aims/shared/holistic-projects/constants';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import {
  BankOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Button, Card, Progress, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  createHolisticProjectAction,
  deleteHolisticProjectAction,
  updateHolisticProjectAction,
} from '../../../../../redux-store/holistic-projects-store';
import { extractHolisticProjectForAdmin } from '../../../constants';
import ChangeProjectStageModal from './ChangeProjectStageModal';
import InitiateAccountingModal from './InitiateAccountingModal';
import ShowInCatalogModal from './ShowInCatalogModal';
import {
  holisticProjectAccountingQuery,
  holisticProjectAccountingUpdatesSubscription,
} from './constants';

const { Title, Text, Paragraph } = Typography;

const queryId = 'holisticProjectAccounting';

function AccountingTab({ project: parentProject, queryId: parentQueryId }) {
  const [showModal, setShowModal] = useState();
  const [showCatalogModal, setShowCatalogModal] = useState();
  const [showStageModal, setShowStageModal] = useState();

  useSingleSomethingUpdatesA10({
    variables: { _id: parentProject?._id },
    subscription: holisticProjectAccountingUpdatesSubscription,
    createAction: createHolisticProjectAction,
    updateAction: updateHolisticProjectAction,
    deleteAction: deleteHolisticProjectAction,
    extractSomething: (resp) => resp?.data?.holisticProjectUpdatesForAdmin,
    queryId,
    skip: !parentProject?._id,
  });
  const { loading, refetch } = useOneM1({
    variables: { _id: parentProject?._id },
    query: holisticProjectAccountingQuery,
    extract: extractHolisticProjectForAdmin,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateHolisticProjectAction,
    skip: !parentProject?._id,
    showError: false,
  });
  const holisticProject = useSingleSomethingA10(
    'holisticProjects',
    parentProject?._id,
    queryId,
  );

  const showInitiateBtn = useMemo(() => {
    return (
      holisticProject &&
      (!holisticProject.projectNo ||
        !holisticProject.fundingAccountId ||
        !holisticProject.payoutAccountId ||
        !holisticProject.paybackAccountId)
    );
  }, [holisticProject]);
  const stage = useMemo(() => {
    if (holisticProject && holisticProject.stage) {
      return HolisticProjectStages[holisticProject.stage];
    }
    return undefined;
  }, [holisticProject]);
  return (
    <>
      {holisticProject && showInitiateBtn && (
        <>
          <div
            style={{
              textAlign: 'center',
              padding: 32,
            }}
          >
            <Button
              type="primary"
              icon={<BankOutlined />}
              onClick={() => setShowModal(true)}
            >
              Initiate Holistic Project Accounting
            </Button>
          </div>
          <InitiateAccountingModal
            showingModal={showModal}
            setShowingModal={setShowModal}
            project={parentProject}
            queryIds={[queryId, parentQueryId]}
          />
        </>
      )}
      {holisticProject && !showInitiateBtn && (
        <div style={{ maxWidth: 800, marginRight: 'auto', marginLeft: 'auto' }}>
          {stage && (
            <>
              <Title level={3}>Stage</Title>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 600,
                    fontSize: 24,
                    marginRight: 16,
                  }}
                >
                  {stage.label}
                </div>
                <Button
                  size="small"
                  type="dashed"
                  onClick={() => setShowStageModal(true)}
                >
                  Change
                </Button>
              </div>
            </>
          )}
          <Title level={3}>Catalog</Title>
          <div style={{ textAlign: 'center' }}>
            {holisticProject.showInCatalog ? (
              <>
                <Paragraph style={{ fontSize: 14 }}>
                  This project is currently{' '}
                  <Text>
                    <EyeOutlined /> VISIBLE
                  </Text>{' '}
                  in the public catalog
                </Paragraph>
                <Paragraph>
                  <Button
                    type="primary"
                    onClick={() => setShowCatalogModal(true)}
                  >
                    Remove from Catalog
                  </Button>
                </Paragraph>
              </>
            ) : (
              <>
                <Paragraph style={{ fontSize: 14 }}>
                  This project is currently{' '}
                  <Text>
                    <EyeInvisibleOutlined /> NOT VISIBLE
                  </Text>{' '}
                  in the public catalog
                </Paragraph>
                <Paragraph>
                  <Button
                    type="primary"
                    onClick={() => setShowCatalogModal(true)}
                  >
                    Show in Catalog
                  </Button>
                </Paragraph>
              </>
            )}
          </div>
          {holisticProject.stage === HolisticProjectStages.FUNDING.key && (
            <>
              <Title level={3}>Project Funding</Title>
              <Progress
                percent={Math.round(holisticProject.fundingPercentage)}
                trailColor="white"
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 16,
                  marginBottom: 32,
                }}
              >
                <div>
                  <Text style={{ fontSize: 14 }}>
                    {displayMoney(
                      getNumberValue(holisticProject.fundingBalance) / 10000,
                    )}
                  </Text>
                </div>
                <div>
                  <Text style={{ fontSize: 14 }}>
                    {displayMoney(
                      getNumberValue(holisticProject.fundingTarget) / 10000,
                    )}
                  </Text>
                </div>
              </div>
            </>
          )}
          {holisticProject.stage === HolisticProjectStages.ACTIVE.key && (
            <>
              <Title level={3}>Loan Payback</Title>
              <Progress
                percent={holisticProject.paybackPercentage}
                trailColor="white"
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 16,
                  marginBottom: 32,
                }}
              >
                <div>
                  <Text style={{ fontSize: 14 }}>
                    {displayMoney(
                      getNumberValue(holisticProject.paybackBalance) / 10000,
                    )}
                  </Text>
                </div>
                <div>
                  <Text style={{ fontSize: 14 }}>
                    {displayMoney(getNumberValue(holisticProject.loanTotal))}
                  </Text>
                </div>
              </div>
            </>
          )}
          <Title level={3}>Accounts</Title>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Card style={{ marginRight: 32, textAlign: 'center' }}>
              <Title level={4}>
                <BankOutlined style={{ marginRight: 8 }} />
                Funding Account
              </Title>
              {holisticProject.fundingAccount &&
                holisticProject.fundingAccount.fund && (
                  <Paragraph style={{ color: sharedSettings.colors.textGray }}>
                    {holisticProject.fundingAccount.fund.name}
                  </Paragraph>
                )}
              <div
                style={{ textAlign: 'center', fontSize: 24, marginBottom: 8 }}
              >
                {displayMoney(
                  getNumberValue(holisticProject.fundingBalance) / 10000,
                )}
              </div>
              <div>
                <Link
                  to={`/accounting/accounts/view/${holisticProject.fundingAccountId}`}
                >
                  View Transactions
                </Link>
              </div>
            </Card>
            <Card styles={{ body: { textAlign: 'center' } }}>
              <Title level={4}>
                <BankOutlined style={{ marginRight: 8 }} />
                Payback Account
              </Title>
              {holisticProject.fundingAccount &&
                holisticProject.paybackAccount.fund && (
                  <Paragraph style={{ color: sharedSettings.colors.textGray }}>
                    {holisticProject.paybackAccount.fund.name}
                  </Paragraph>
                )}
              <div
                style={{ textAlign: 'center', fontSize: 24, marginBottom: 8 }}
              >
                {displayMoney(
                  getNumberValue(holisticProject.paybackBalance) / 10000,
                )}
              </div>
              <div>
                <Link
                  to={`/accounting/accounts/view/${holisticProject.paybackAccountId}`}
                >
                  View Transactions
                </Link>
              </div>
            </Card>
          </div>
          <ShowInCatalogModal
            showingModal={showCatalogModal}
            setShowingModal={setShowCatalogModal}
            project={holisticProject}
            queryIds={[queryId, parentQueryId]}
          />
          <ChangeProjectStageModal
            showingModal={showStageModal}
            setShowingModal={setShowStageModal}
            project={holisticProject}
            queryIds={[queryId, parentQueryId]}
          />
        </div>
      )}
    </>
  );
}

export default AccountingTab;
