import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tag, Tooltip, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import settings from '../settings';

const { Text, Title } = Typography;

function ProjectSponsorshipsTable({
  sponsorships,
  loading,
  hasNextPage,
  next,
}) {
  const location = useLocation();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((pages, _, sorters) => {
    setPagination({
      ...pages,
    });
  }, []);
  const locale = useSelector((store) => store.locale, shallowEqual);
  const handleLoadMore = useCallback(() => {
    next();
  }, [next]);
  return (
    <>
      <Table
        dataSource={sponsorships}
        loading={!sponsorships.length && loading}
        pagination={{ ...pagination, total: sponsorships.length }}
        onChange={handleTableChange}
        // style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: 1200 }}
      >
        <Table.Column
          title="Sponsor Name"
          key="sponsorName"
          width={150}
          render={(text, record) => {
            return (
              <Link
                to={`/contact/view/${record.contactId}/dashboard?from=${location.pathname}`}
              >
                {record.sponsorName}
              </Link>
            );
          }}
        />
        <Table.Column
          title="Project Name"
          key="projectName"
          width={150}
          render={(text, record) => {
            return (
              <Link
                to={`/holistic-project/view/${record.projectId}/details?from=${location.pathname}`}
              >
                {record.projectName}
              </Link>
            );
          }}
        />
        <Table.Column
          title="Tags"
          key="tags"
          render={(text, record) => {
            return (
              record.tags &&
              record.tags.map((tag) => (
                <Tag
                  key={tag.key}
                  color={sharedSettings.colors.primary}
                  style={{ marginBottom: 4 }}
                >
                  {tag.key}
                </Tag>
              ))
            );
          }}
        />
        <Table.Column
          title="Amount"
          key="amount"
          render={(text, record) => {
            return displayMoney(getNumberValue(record.amount) / 10000);
          }}
        />
        <Table.Column
          title="Donation Type"
          key="recurring"
          render={(text, record) => {
            return record.recurring ? 'Monthly Recurring' : 'One-Time';
          }}
        />
        <Table.Column
          title="Start Date"
          key="startDate"
          render={(text, record) => {
            return (
              record.startDate &&
              new Intl.DateTimeFormat(locale, {
                dateStyle: 'medium',
              }).format(new Date(record.startDate))
            );
          }}
        />
        <Table.Column
          title="Account Balance"
          key="accountBalance"
          width={200}
          render={(text, record) => {
            return (
              record.accountId && (
                <>
                  <Text style={{ marginRight: 16 }}>
                    {displayMoney(
                      getNumberValue(record.accountBalance) / 10000,
                    )}
                  </Text>
                  <Link to={`/accounting/accounts/view/${record.accountId}`}>
                    View Account
                  </Link>
                </>
              )
            );
          }}
        />
        <Table.Column
          title="Last Donation Date"
          key="startDate"
          render={(text, record) => {
            return (
              record.lastPaymentDate &&
              new Intl.DateTimeFormat(locale, {
                dateStyle: 'medium',
              }).format(new Date(record.lastPaymentDate))
            );
          }}
        />
        <Table.Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="View">
                <Link
                  to={`/project-sponsorship/view/${record._id}?from=${location.pathname}`}
                >
                  <Button icon={<EyeOutlined />} />
                </Link>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <div className="bottom-actions">
        {hasNextPage && <Button onClick={handleLoadMore}>Load More</Button>}
      </div>
      <style jsx>{`
        .bottom-actions {
          text-align: center;
        }
      `}</style>
    </>
  );
}

export default ProjectSponsorshipsTable;
