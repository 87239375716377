import {
  HolisticProjectStages,
  HolisticProjectStates,
  HolisticProjectTypes,
} from '@aims/shared/holistic-projects/constants';
import { getPgDisplayName } from '@aims/shared/people-groups/utils';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { Card, Tag, Typography } from 'antd';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import DetailsGrid from '../../../../../components/DetailsGrid';
import ThingChangeRegionInner from '../../../../../components/ThingChangeRegionInner';
import ThingChangeTagsInner from '../../../../../components/ThingChangeTagsInner';
import ThingChangeTextAreaInner from '../../../../../components/ThingChangeTextAreaInner';
import ThingChangeTextInner from '../../../../../components/ThingChangeTextInner';
import HProjectChangeLoanStartDateInner from '../../../EditHolisticProject/HProjectChangeLoanStartDateInner';
import HProjectChangeLoanTermInner from '../../../EditHolisticProject/HProjectChangeLoanTermInner';
import HProjectChangeLoanTotalInner from '../../../EditHolisticProject/HProjectChangeLoanTotalInner';
import HProjectChangeTypeInner from '../../../EditHolisticProject/HProjectChangeTypeInner';
import HProjectChangeUpgsInner from '../../../EditHolisticProject/HProjectChangeUpgsInner';
import HProjectChangeSomethingModal from '../../../HProjectChangeSomethingModal';
import ConnectedContact from './ConnectedContact';
import HProjectChangeCoordinatorInner from '../../../EditHolisticProject/HProjectChangeCoordinatorInner';
import HProjectChangeResponsiblePersonsInner from '../../../EditHolisticProject/HProjectChangeResponsiblePersonsInner';
import HProjectChangeSupportedFWsInner from '../../../EditHolisticProject/HProjectChangeSupportedFWsInner';
import HProjectChangeStageInner from '../../../EditHolisticProject/HProjectChangeStageInner';
import HProjectChangeStateInner from '../../../EditHolisticProject/HProjectChangeStateInner';

const { Title, Text } = Typography;

function HProjectDetailsCard({ holisticProject }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const [projectDetails, loanDetails, people] = useMemo(() => {
    if (holisticProject) {
      return [
        [
          [
            {
              title: 'Project Name',
              value: holisticProject.projectName,
              span: 6,
              edit: (
                <HProjectChangeSomethingModal holisticProject={holisticProject}>
                  <ThingChangeTextInner
                    name="projectName"
                    title="Change Project Name"
                    label="Project Name"
                  />
                </HProjectChangeSomethingModal>
              ),
            },
            {
              title: 'Project Type',
              value: HolisticProjectTypes[holisticProject.typeOfProject]?.label,
              span: 6,
              edit: (
                <HProjectChangeSomethingModal holisticProject={holisticProject}>
                  <HProjectChangeTypeInner />
                </HProjectChangeSomethingModal>
              ),
            },
            {
              title: 'Project #',
              value: holisticProject.projectNo,
              span: 6,
            },
            {
              title: 'Region',
              value: holisticProject.region,
              span: 6,
              edit: (
                <HProjectChangeSomethingModal holisticProject={holisticProject}>
                  <ThingChangeRegionInner label="Holistic Project Region" />
                </HProjectChangeSomethingModal>
              ),
            },
            {
              title: 'Stage',
              value: HolisticProjectStages[holisticProject.stage]?.label,
              span: 6,
              edit: (
                <HProjectChangeSomethingModal holisticProject={holisticProject}>
                  <HProjectChangeStageInner />
                </HProjectChangeSomethingModal>
              ),
            },
            holisticProject.status && {
              title: 'State',
              value: HolisticProjectStates[holisticProject.status]?.label,
              span: 6,
              edit: (
                <HProjectChangeSomethingModal holisticProject={holisticProject}>
                  <HProjectChangeStateInner />
                </HProjectChangeSomethingModal>
              ),
            },
            {
              title: 'People Groups',
              component: holisticProject.peopleGroups?.map((pg) => (
                <div key={pg._id}>
                  <Text>{getPgDisplayName(pg)}</Text>
                </div>
              )),
              span: 12,
              edit: (
                <HProjectChangeSomethingModal holisticProject={holisticProject}>
                  <HProjectChangeUpgsInner />
                </HProjectChangeSomethingModal>
              ),
            },
            {
              title: 'Description',
              value: holisticProject.desc,
              span: 24,
              edit: (
                <HProjectChangeSomethingModal holisticProject={holisticProject}>
                  <ThingChangeTextAreaInner
                    name="desc"
                    title="Update Description"
                    label="Description"
                  />
                </HProjectChangeSomethingModal>
              ),
            },
            {
              title: 'Tags',
              component: (
                <div style={{ paddingTop: 4 }}>
                  {holisticProject.tags?.map((tag) => (
                    <Tag
                      key={tag.key}
                      color={sharedSettings.colors.organizationColor}
                      style={{ marginBottom: 8 }}
                    >
                      {tag.key}
                    </Tag>
                  ))}
                </div>
              ),
              span: 12,
              edit: (
                <HProjectChangeSomethingModal holisticProject={holisticProject}>
                  <ThingChangeTagsInner />
                </HProjectChangeSomethingModal>
              ),
            },
          ].filter((a) => a),
        ],
        [
          [
            {
              title: 'Loan Total',
              value: displayMoney(getNumberValue(holisticProject.loanTotal)),
              span: 6,
              edit: (
                <HProjectChangeSomethingModal holisticProject={holisticProject}>
                  <HProjectChangeLoanTotalInner />
                </HProjectChangeSomethingModal>
              ),
            },
            {
              title: 'Loan Term',
              value: `${getNumberValue(holisticProject.loanTerm)} Months`,
              span: 6,
              edit: (
                <HProjectChangeSomethingModal holisticProject={holisticProject}>
                  <HProjectChangeLoanTermInner />
                </HProjectChangeSomethingModal>
              ),
            },
            {
              title: 'Loan Start Date',
              value:
                holisticProject.loanStartDate &&
                new Intl.DateTimeFormat(locale, {
                  dateStyle: 'medium',
                }).format(new Date(holisticProject.loanStartDate)),
              span: 6,
              edit: (
                <HProjectChangeSomethingModal holisticProject={holisticProject}>
                  <HProjectChangeLoanStartDateInner />
                </HProjectChangeSomethingModal>
              ),
            },
          ].filter((a) => a),
        ],
        [
          [
            {
              title: 'Project Coordinator',
              component: (
                <div style={{ paddingTop: 8 }}>
                  <ConnectedContact
                    contact={holisticProject.regionalCoordinator}
                  />
                </div>
              ),
              span: 8,
              edit: (
                <HProjectChangeSomethingModal holisticProject={holisticProject}>
                  <HProjectChangeCoordinatorInner />
                </HProjectChangeSomethingModal>
              ),
            },
            {
              title: 'Responsible Persons',
              component: (
                <div style={{ paddingTop: 8 }}>
                  {holisticProject.responsiblePersons.map((person) => (
                    <ConnectedContact
                      key={person.contactId}
                      role={person.role}
                      contact={person.contact}
                    />
                  ))}
                </div>
              ),
              span: 8,
              edit: (
                <HProjectChangeSomethingModal holisticProject={holisticProject}>
                  <HProjectChangeResponsiblePersonsInner />
                </HProjectChangeSomethingModal>
              ),
            },
            {
              title: 'Supported Field Workers',
              component: (
                <div style={{ paddingTop: 8 }}>
                  {holisticProject.supportedFieldWorkers.map((person) => (
                    <ConnectedContact
                      key={person.contactId}
                      contact={person.contact}
                    />
                  ))}
                </div>
              ),
              span: 8,
              edit: (
                <HProjectChangeSomethingModal holisticProject={holisticProject}>
                  <HProjectChangeSupportedFWsInner />
                </HProjectChangeSomethingModal>
              ),
            },
          ],
        ],
      ];
    }
    return [[], [], []];
  }, [holisticProject, locale]);
  return (
    <>
      <Title level={3}>Project Details</Title>
      <Card style={{ marginBottom: 24 }}>
        <DetailsGrid details={projectDetails} gutter={[16, 16]} />
      </Card>
      <Title level={3}>Loan Details</Title>
      <Card style={{ marginBottom: 24 }}>
        <DetailsGrid details={loanDetails} gutter={[16, 16]} />
      </Card>
      <Title level={3}>People</Title>
      <Card style={{ marginBottom: 24 }}>
        <DetailsGrid details={people} gutter={[16, 16]} />
      </Card>
    </>
  );
}

export default HProjectDetailsCard;
