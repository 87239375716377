import { Form } from 'antd';
import React, { useCallback, useEffect } from 'react';
import DateFormItem from '@aims/shared/components/DateFormItem';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';

function HProjectChangeLoanStartDateInner({
  holisticProject,
  saving,
  handleFinish,
  onCancel,
  title = 'Change Loan Term',
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (holisticProject) {
      form.setFieldsValue({
        loanStartDate: holisticProject.loanStartDate,
      });
    }
  }, [holisticProject, form]);
  const _handleFinish = useCallback(
    (_values) => {
      const { loanStartDate, ...values } = _values;
      handleFinish({
        loanStartDate,
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <TaskSomethingForm
      title={title}
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <DateFormItem name="loanStartDate" label="Loan Start Date" allowClear />
    </TaskSomethingForm>
  );
}

export default HProjectChangeLoanStartDateInner;
