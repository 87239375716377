import {
  HolisticProjectStages,
  HolisticProjectTypes,
} from '@aims/shared/holistic-projects/constants';
import SelectPeopleGroup from '@aims/shared/people-groups/SelectPeopleGroup';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  notification,
} from 'antd';
import React, { useCallback, useEffect } from 'react';
import DateFormItem from '@aims/shared/components/DateFormItem';
import settings from '../../../settings';
import { orderAndFilterFormList } from '../../../shared/utils';
import SelectContact from '../../contacts/SelectContact';
import SelectRegionForAdmin from '../../people_groups/ListPeopleGroups/SelectRegionForAdmin';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';
import {
  createHolisticProjectMutation,
  updateHolisticProjectMutation,
} from '../constants';

const { Text } = Typography;

function EditHolisticProjectForm({
  _id,
  handleCancel,
  handleSuccess,
  holisticProject,
  saving,
  setSaving,
  formStyle,
  fieldsToSet = {},
}) {
  const [updateHolisticProject] = useMutation(updateHolisticProjectMutation);
  const [createHolisticProject] = useMutation(createHolisticProjectMutation);

  const [form] = Form.useForm();

  useEffect(() => {
    if (holisticProject) {
      const { peopleGroupIds, loanTotal, ...otherFields } = holisticProject;
      otherFields.peopleGroups = (peopleGroupIds || []).map((id) => ({
        peopleGroupId: id,
      }));
      otherFields.loanTotal = Math.round(loanTotal * 100) / 100;
      form.setFieldsValue(otherFields);
    }
  }, [holisticProject, form]);

  const onSubmit = useCallback(
    async (_values) => {
      setSaving(true);
      const {
        supportedFieldWorkers,
        responsiblePersons,
        peopleGroups,
        ...values
      } = _values;
      const peopleGroupIds = (peopleGroups || []).map((p) => p.peopleGroupId);
      const _supportedFieldWorkers = orderAndFilterFormList(
        supportedFieldWorkers,
        (a, b) => {
          return a.contactId.localeCompare(b.contactId);
        },
        'contactId',
        ['contactId', 'totalReceived'],
      );
      const _responsiblePersons = orderAndFilterFormList(
        responsiblePersons,
        (a, b) => {
          return a.contactId.localeCompare(b.contactId);
        },
        'contactId',
        ['contactId', 'role'],
      );
      try {
        if (holisticProject) {
          await updateHolisticProject({
            variables: {
              holisticProject: {
                _id,
                ...values,
                supportedFieldWorkers: _supportedFieldWorkers,
                responsiblePersons: _responsiblePersons,
                peopleGroupIds,
                ...fieldsToSet,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Holistic project updated successfully',
          });
        } else {
          const result = await createHolisticProject({
            variables: {
              holisticProject: {
                _id,
                ...values,
                supportedFieldWorkers: _supportedFieldWorkers,
                responsiblePersons: _responsiblePersons,
                peopleGroupIds,
                ...fieldsToSet,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Holistic project created successfully',
          });
        }
        if (handleSuccess) {
          handleSuccess();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your holistic project',
        });
      }
      setSaving(false);
    },
    [
      holisticProject,
      _id,
      createHolisticProject,
      updateHolisticProject,
      fieldsToSet,
      setSaving,
      handleSuccess,
    ],
  );

  return (
    <TaskSomethingForm
      form={form}
      onFinish={onSubmit}
      onCancel={handleCancel}
      saving={saving}
      formStyle={formStyle}
    >
      <Form.Item
        label="Project Name"
        name="projectName"
        rules={[{ required: true, message: 'Please enter a project name' }]}
      >
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item
        label="Project Type"
        name="typeOfProject"
        rules={[{ required: true, message: 'Please select a project type' }]}
      >
        <Select disabled={saving}>
          {Object.entries(HolisticProjectTypes).map(([key, obj]) => (
            <Select.Option key={key} value={key}>
              {obj.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Project Description"
        name="desc"
        rules={[
          { required: true, message: 'Please enter a project description' },
        ]}
      >
        <Input.TextArea rows={4} disabled={saving} />
      </Form.Item>
      <Form.Item
        label="Project Stage"
        name="stage"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Select style={{ width: 200 }} disabled={saving}>
          {Object.entries(HolisticProjectStages).map(([key, obj]) => (
            <Select.Option key={obj.key} value={obj.key}>
              {obj.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Project Coordinator"
        name="regionalCoordId"
        rules={[{ required: true, message: 'Please select a coordinator' }]}
      >
        <SelectContact disabled={saving} filters={{ isRegionalCoord: true }} />
      </Form.Item>
      <Form.Item
        label="Region"
        name="regionCode"
        rules={[{ required: true, message: 'Please select a region' }]}
      >
        <SelectRegionForAdmin disabled={saving} />
      </Form.Item>
      <Form.Item label="Which people groups will be affected by this project?">
        <Form.List name="peopleGroups">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  key={field.key}
                  style={{ marginBottom: 0 }}
                  shouldUpdate={(prev, curr) =>
                    prev.onBehalfOf !== curr.onBehalfOf
                  }
                >
                  {({ getFieldValue }) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Form.Item
                        label={`People Group ${index + 1}`}
                        name={[field.name, 'peopleGroupId']}
                        style={{ width: '100%' }}
                        rules={[
                          {
                            required: true,
                            message: 'Please select a people group',
                          },
                        ]}
                      >
                        <SelectPeopleGroup
                          settings={settings}
                          disabled={saving}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                        style={{ marginLeft: 8 }}
                      />
                    </div>
                  )}
                </Form.Item>
              ))}
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add people group
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Divider>Loan Details</Divider>
      <Form.Item
        label="Loan Total"
        name="loanTotal"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <InputNumber
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          disabled={saving}
          min={0}
          step={1}
          style={{ width: 200 }}
        />
      </Form.Item>
      <Form.Item
        label="Loan Term"
        name="loanTerm"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Select disabled={saving} style={{ width: 200 }}>
          {[6, 12, 18, 24, 30, 36, 42, 48].map((months) => (
            <Select.Option key={months} value={months}>
              {`${months} Months`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <DateFormItem name="loanStartDate" label="Loan Start Date" allowClear />
      <Divider>Field Workers</Divider>
      <Form.Item label="Please list the field workers that will be financially supported by this project">
        <Form.List name="supportedFieldWorkers">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  key={field.key}
                  style={{ marginBottom: 0 }}
                  shouldUpdate={(prev, curr) =>
                    prev.regionalCoordId !== curr.regionalCoordId
                  }
                >
                  {({ getFieldValue }) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Form.Item
                        label={`Field Worker ${index + 1}`}
                        name={[field.name, 'contactId']}
                        style={{ width: '100%' }}
                        rules={[
                          { required: true, message: 'This field is required' },
                        ]}
                      >
                        <SelectContact
                          disabled={saving}
                          filters={{
                            isFieldWorker: true,
                            regionalCoordId: getFieldValue('regionalCoordId'),
                          }}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                        style={{ marginLeft: 8 }}
                      />
                    </div>
                  )}
                </Form.Item>
              ))}
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add field worker
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Divider>Responsible Persons</Divider>
      <Form.Item label="Who will be taking responsibility for the planning, management and implementation of this project?">
        <Form.List name="responsiblePersons">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item key={field.key} style={{ marginBottom: 0 }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <div className="person" style={{ flex: 1 }}>
                      <Form.Item
                        key={field.key}
                        style={{ marginBottom: 0 }}
                        shouldUpdate={(prev, curr) =>
                          prev.regionalCoordId !== curr.regionalCoordId
                        }
                      >
                        {({ getFieldValue }) => (
                          <Form.Item
                            label="Contact"
                            name={[field.name, 'contactId']}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <SelectContact
                              disabled={saving}
                              filters={{
                                isFieldWorker: true,
                                regionalCoordId:
                                  getFieldValue('regionalCoordId'),
                              }}
                            />
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item
                        label="Role"
                        name={[field.name, 'role']}
                        rules={[
                          { required: true, message: 'This field is required' },
                        ]}
                      >
                        <Input disabled={saving} />
                      </Form.Item>
                    </div>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        remove(field.name);
                      }}
                      style={{ marginLeft: 8 }}
                    />
                  </div>
                </Form.Item>
              ))}
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add responsible person
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </TaskSomethingForm>
  );
}

export default EditHolisticProjectForm;
