import { getPgDisplayName } from '@aims/shared/people-groups/utils';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tooltip } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import settings from '../../settings';
import {
  allHolisticProjectsForAdminQuery,
  extractHolisticProjectsForAdmin,
} from './constants';
import {
  HolisticProjectStages,
  HolisticProjectTypes,
} from '@aims/shared/holistic-projects/constants';
import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import FilterHolisticProjects from './FilterHolisticProjects';

function HolisticProjectsList({ filters: parentFilters }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const filters = useRef({
    humanReadableId: -1,
    ...parentFilters,
  });

  const {
    error,
    loading,
    data: holisticProjects,
    search: holisticProjectSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allHolisticProjectsForAdminQuery,
    extract: extractHolisticProjectsForAdmin,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId: 'holisticProjectsList',
  });

  const locale = useSelector((store) => store.locale, shallowEqual);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params.pagination,
    });
  }, []);

  return (
    <>
      <FilterHolisticProjects
        filters={filters}
        refetch={refetch}
        reportSearch={holisticProjectSearch}
        loading={loading}
        sortBy={sortBy}
        type={'holisticProjectList'}
      />
      <Table
        dataSource={holisticProjects}
        loading={!holisticProjects.length && loading}
        pagination={{ ...pagination, total: holisticProjects.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: 1200 }}
      >
        <Column
          title="Project #"
          dataIndex="projectNo"
          key="projectNo"
          width="8em"
        />
        <Column
          title="Project Name"
          dataIndex="projectName"
          key="projectName"
        />
        <Column
          title="Coordinator"
          dataIndex="regionalCoordId"
          render={(text, record) => {
            return (
              record.regionalCoordinator && (
                <Link
                  to={`/contact/view/${record.regionalCoordinator._id}/dashboard`}
                >
                  {record.regionalCoordinator.fullName}
                </Link>
              )
            );
          }}
        />
        <Column
          title="People Groups"
          dataIndex="peopleGroupIds"
          render={(text, record) => {
            return (
              record.peopleGroups &&
              record.peopleGroups.map((pg) => (
                <Link
                  key={pg._id}
                  style={{ marginRight: 8 }}
                  to={`/people-group/view/${pg._id}/info`}
                >
                  {getPgDisplayName(pg)}
                </Link>
              ))
            );
          }}
        />
        <Column
          title="Loan Amount"
          dataIndex="loanTotal"
          render={(text, record) => {
            return displayMoney(getNumberValue(record.loanTotal || 0));
          }}
        />
        <Column
          title="Created"
          dataIndex="createdAt"
          render={(text, record) => {
            return new Intl.DateTimeFormat(locale, {
              dateStyle: 'short',
            }).format(new Date(record.createdAt));
          }}
        />
        <Column
          title="Visible in Catalog"
          dataIndex="showInCatalog"
          width={120}
          render={(text, record) => {
            return record.showInCatalog ? 'Yes' : 'No';
          }}
        />
        <Column
          title="Stage"
          dataIndex="stage"
          key="stage"
          render={(text, record) => {
            return HolisticProjectStages[record.stage]?.label;
          }}
        />
        <Column
          title="Project Type"
          dataIndex="typeOfProject"
          key="typeOfProject"
          render={(text, record) => {
            return HolisticProjectTypes[record.typeOfProject]?.label;
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="View">
                <Link
                  to={`/holistic-project/view/${record._id}/details?from=${location.pathname}`}
                >
                  <Button icon={<EyeOutlined />} />
                </Link>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <div>{hasNextPage && <Button onClick={next}>Load More</Button>}</div>
    </>
  );
}

export default HolisticProjectsList;
