import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { PlusCircleOutlined, SelectOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useRef, useState } from 'react';
import settings from '../../../settings';
import AccountFilters from './AccountFilters';
import AccountsTable from './AccountsTable';
import { allAccountsQuery, extractAllAccounts } from './constants';
import AccountsSelectToolbar from './AccountsSelectToolbar';
import AccountCreateModal from './AccountCreateModal';

const queryId = 'accountsTab';

function AccountsTab({ filters: parentFilters, selected, setSelected }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const filters = useRef({
    ...parentFilters,
  });

  const {
    error,
    loading,
    data: accounts,
    search: accountSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allAccountsQuery,
    extract: extractAllAccounts,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  const [creating, setCreating] = useState(false);
  const onCreate = () => setCreating(true);
  const onCancelCreate = () => setCreating(false);
  const onFinishCreate = () => {
    refetch();
    setCreating(false);
  };

  const [hideUnselected, setHideUnselected] = useState();

  return (
    <>
      <div style={{ marginBottom: 16, marginLeft: -16 }}>
        <Button
          type="text"
          icon={<PlusCircleOutlined />}
          size="large"
          onClick={onCreate}
        >
          Create a New Account
        </Button>
      </div>
      <AccountsSelectToolbar
        selected={selected}
        hideUnselected={hideUnselected}
        setSelected={setSelected}
        setHideUnselected={setHideUnselected}
      />
      <AccountFilters
        filtersRef={filters}
        refetch={refetch}
        search={accountSearch}
        sortByRef={sortBy}
        parentFilters={parentFilters}
        sortedBy={sortedBy}
        sortOrder={sortOrder}
        more={
          <Button
            icon={<SelectOutlined />}
            type="dashed"
            onClick={() => setSelected(accounts)}
            style={{ marginRight: 16 }}
            shape="round"
          >
            Select All
          </Button>
        }
      />
      <AccountsTable
        accounts={hideUnselected ? selected : accounts}
        loading={loading}
        selected={selected}
        setSelected={setSelected}
      />
      <div>{hasNextPage && <Button onClick={next}>Load More</Button>}</div>
      <AccountCreateModal
        visible={creating}
        onCancel={onCancelCreate}
        onFinish={onFinishCreate}
      />
    </>
  );
}

export default AccountsTab;
