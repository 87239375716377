const settings = {
  pageSize: 25,
  largePage: 50,
  smallPage: 5,
  querySize: 100,
  cacheTimeout: 300000,
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:8003/graphql/',
  apiWsUrl: process.env.REACT_APP_API_WS_URL || 'ws://localhost:8003/graphql/',
  webUrl: process.env.REACT_APP_WEB_URL || 'http://localhost:3000',
  userAppUrl: process.env.REACT_APP_USER_APP_URL || 'http://localhost:3001',
  googleOAuthRedirect:
    process.env.REACT_APP_GOOGLE_REDIRECT_URL || 'http://localhost:3000/home',
  googleOAuthClientId:
    '949777386714-glaf5pg9i3htagj0vnallclvhe0e8uhs.apps.googleusercontent.com',
  screenSizes: {
    lg: 992,
    md: 600,
    sm: 400,
  },
  timeFormat: 'h:mm a',
  tagColors: [
    '#cf1322',
    '#d4380d',
    '#d46b08',
    '#d48806',
    '#d4b106',
    '#7cb305',
    '#389e0d',
    '#08979c',
    '#096dd9',
    '#1d39c4',
    '#531dab',
    '#c41d7f',
    '#8c8c8c',
  ],
  sponsorANativeMissionaryFund:
    process.env.REACT_APP_WEB_URL &&
    process.env.REACT_APP_WEB_URL.includes('aims.org')
      ? 'RYNY7bnnTayyYQhs2BdU9w'
      : 'QS5FYtILZGxHXTA_c7YNTg', // dev
};

export default settings;
