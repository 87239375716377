import {
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Empty, List, Space, Tooltip, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import settings from '../../settings';
import ActivityCard from './ActivityCard';
import CreateActivityModal from './CreateActivityModal';
import useActivities from '../../pages/activity/use-activities';
import useHistoryV2 from '../../pages/historyV2/use-history-v2';
import HistoryV2Card from '../../pages/historyV2/HistoryV2Card';

const { Title } = Typography;

function AnyActivity({
  feedId,
  filters,
  fieldsToSet,
  queryId = 'default',
  skip,
}) {
  const [showActivityModel, setShowActivityModal] = useState(null);
  const [showHistory, setShowHistory] = useState(true);
  const [numShown, setNumShown] = useState(settings.pageSize);

  const { loading: loadingActivity, data: activities } = useActivities({
    filters,
    queryId,
    skip,
    sortByFunc: (a, b) => {
      return b.createdAt?.localeCompare(a.createdAt);
    },
  });

  const { loading: loadingHistory, data: history } = useHistoryV2({
    filters,
    queryId,
    sortByFunc: (a, b) => {
      return b.changedAt?.localeCompare(a.changedAt);
    },
    filterFunc: (a) => {
      let ret = true;
      if (filters.contactId && a.contactId !== filters.contactId) {
        ret = false;
      }
      return ret;
    },
  });

  const loading = loadingActivity || loadingHistory;

  const items = useMemo(() => {
    return [...activities, ...((showHistory && history) || [])].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
    );
  }, [activities, history, showHistory]);

  return (
    <div>
      <div style={{ display: 'flex', marginBottom: 8 }}>
        <Title level={2}>{`Notes & Activity`}</Title>
        <div style={{ flex: 1 }} />
        <Space>
          <Button
            onClick={() => setShowHistory(!showHistory)}
            icon={showHistory ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          >
            {showHistory ? 'Hide History' : 'Show History'}
          </Button>
          <Tooltip title="Add Activity Log">
            <Button
              onClick={() => setShowActivityModal('__new__')}
              icon={<PlusOutlined />}
            />
          </Tooltip>
        </Space>
      </div>
      <List
        rowKey="_id"
        style={{ maxWidth: 1000 }}
        grid={{ gutter: 16, column: 1 }}
        dataSource={items.slice(0, numShown)}
        renderItem={(item) => (
          <List.Item key={item._id} style={{ borderBottom: 'none' }}>
            {item.__typename === 'HistoryV2NodeForAdmin' ? (
              <HistoryV2Card history={item} />
            ) : (
              <ActivityCard
                key={item._id}
                activity={item}
                setShowActivityModal={setShowActivityModal}
              />
            )}
          </List.Item>
        )}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Activity"
            />
          ),
        }}
        loadMore={
          items.length > numShown && (
            <div className="load-more">
              <Button onClick={() => setNumShown(numShown + settings.pageSize)}>
                Load More
              </Button>
            </div>
          )
        }
      />
      <CreateActivityModal
        visible={showActivityModel}
        setVisible={setShowActivityModal}
        fieldsToSet={fieldsToSet}
        queryId={queryId}
      />
      <style jsx>{`
        .load-more {
          display: flex;
          justify-content: center;
          padding-top: 16px;
          padding-bottom: 16px;
        }
      `}</style>
    </div>
  );
}

export default AnyActivity;
