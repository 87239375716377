import DateFormItem from '@aims/shared/components/DateFormItem';
import { generateUuid } from '@aims/shared/shared/utils';
import { useMutation } from '@apollo/client';
import { Form, notification, Select, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import MyAttachmentsFormItem from '../../../components/AnyAttachments/MyAttachmentsFormItem';
import ContentEditableInput, {
  serializeText,
} from '../../../components/ContentEditableInput';
import { ContactTypes } from '../../contacts/constants';
import SelectContact from '../../contacts/SelectContact';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';
import {
  ActivityTypes,
  createActivityMutation,
  updateActivityMutation,
} from './constants';

const { Text } = Typography;

function EditActivityForm({
  _id,
  handleCancel,
  handleSuccess,
  activity,
  loading,
  setLoading,
  fieldsToSet = {},
}) {
  const [updateActivity] = useMutation(updateActivityMutation);
  const [createActivity] = useMutation(createActivityMutation);

  const [form] = Form.useForm();

  useEffect(() => {
    if (activity) {
      const { eventId, contactId, taskId, ..._ } = activity;
      form.setFieldsValue({
        eventId: eventId || undefined,
        contactId: contactId || undefined,
        taskId: taskId || undefined,
        ..._,
      });
    } else {
      form.setFieldsValue({
        when: new Date().toISOString(),
      });
    }
  }, [form, activity]);

  const onSubmit = useCallback(
    async (_values) => {
      setLoading(true);
      try {
        const { desc, ...values } = _values;
        if (activity) {
          await updateActivity({
            variables: {
              activity: {
                _id,
                ...values,
                desc,
                descText: desc && serializeText(JSON.parse(desc)),
                ...fieldsToSet,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Activity updated successfully',
          });
        } else {
          await createActivity({
            variables: {
              activity: {
                _id,
                ...values,
                desc,
                descText: desc && serializeText(JSON.parse(desc)),
                ...fieldsToSet,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Activity created successfully',
          });
        }
        if (handleSuccess) {
          handleSuccess();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your activity.',
        });
      }
      setLoading(false);
    },
    [
      activity,
      _id,
      createActivity,
      updateActivity,
      setLoading,
      fieldsToSet,
      handleSuccess,
    ],
  );

  const getUploadIds = useCallback(() => {
    const attachmentId = generateUuid();
    const s3Key = `activity/${_id}/attachments/${attachmentId}`;
    return [
      attachmentId,
      s3Key,
      ['admin', ...Object.keys(fieldsToSet), 'activity', 'attachment'],
    ];
  }, [_id, fieldsToSet]);

  return (
    <TaskSomethingForm
      form={form}
      onFinish={onSubmit}
      onCancel={handleCancel}
      saving={loading}
    >
      <Form.Item name="desc">
        <ContentEditableInput disabled={loading} />
      </Form.Item>
      <Form.Item name="type" label="Type" initialValue="PHONE_CALL">
        <Select style={{ width: '100%' }}>
          {ActivityTypes.map(({ label, value }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {!fieldsToSet.contactId && (
        <Form.Item
          label="Contact"
          extra="Associate this activity with a contact"
          name="contactId"
        >
          <SelectContact
            disabled={loading}
            filters={{
              contactType: ContactTypes.CONTACT.key,
            }}
          />
        </Form.Item>
      )}
      <DateFormItem
        name="when"
        label="When"
        rules={[{ required: true, message: 'This field is required' }]}
        showTime
      />
      <MyAttachmentsFormItem
        parentId={_id}
        attachments={activity?.attachments}
        getUploadIds={getUploadIds}
        name="attachmentIds"
        label="Attachments"
      />
    </TaskSomethingForm>
  );
}

export default EditActivityForm;
