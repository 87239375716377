import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { HeartOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import settings from '../../settings';
import CreateUpgPrayerAdoptionModal from './CreateUpgPrayerAdoptionModal';
import ListUpgPrayerAdoptions from './ListUpgPrayerAdoptions';
import {
  allUpgPrayerAdoptionsQuery,
  extractUpgPrayerAdoptions,
} from './constants';

const { Title } = Typography;

function ListUpgPrayerAdoptionsPage() {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const filters = useRef({});
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: upgPrayerAdoptions,
    search: upgPrayerAdoptionsSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allUpgPrayerAdoptionsQuery,
    extract: extractUpgPrayerAdoptions,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId: 'prayer-adoptions-page',
  });

  const onSortedByChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: value,
          order: sortOrder,
        },
      ];
      refetch();
      setSortedBy(value);
    },
    [sortOrder, refetch],
  );

  const onSortOrderChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: sortedBy,
          order: value,
        },
        { key: '_score', order: 'DESC' },
      ];
      refetch();
      setSortOrder(value);
    },
    [sortedBy, refetch],
  );

  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        upgPrayerAdoptionsSearch(search);
      } else {
        filters.current = {
          isTemplate: true,
          ...filters.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v ? v : undefined;
            return prev;
          }, {}),
        };
        refetch();
      }
    },
    [upgPrayerAdoptionsSearch, refetch, sortedBy, sortOrder],
  );

  const history = useHistory();
  const [creating, setCreating] = useState();
  const handleCreate = useCallback(() => {
    setCreating({});
  }, []);
  const handleCancelCreate = useCallback(() => {
    setCreating(false);
  }, []);
  const handleCreateFinish = useCallback(
    (createId) => {
      history.push(`/upg-prayer-adoptions/view/${createId}`);
    },
    [history],
  );

  return (
    <>
      <CSPage
        containerStyle={{ maxWidth: 'unset' }}
        title="UPG Prayer Adoptions"
      >
        <CSPageHeader
          title="UPG Prayer Adoptions"
          titleComponent={
            <Title>
              <HeartOutlined style={{ marginRight: 16 }} />
              UPG Prayer Adoptions
            </Title>
          }
          topActions={[
            <Button
              key="create"
              type="text"
              icon={<PlusCircleOutlined />}
              size="large"
              onClick={handleCreate}
            >
              Create a UPG Prayer Adoption
            </Button>,
          ]}
        />
        <ListUpgPrayerAdoptions
          loading={loading}
          error={error}
          upgPrayerAdoptions={upgPrayerAdoptions}
          next={next}
          hasNextPage={hasNextPage}
          refetch={refetch}
          isTemplate={false}
          onFiltersChanged={onFiltersChanged}
          onSortedByChange={onSortedByChange}
          onSortOrderChange={onSortOrderChange}
        />
      </CSPage>
      <CreateUpgPrayerAdoptionModal
        visible={creating}
        onFinish={handleCreateFinish}
        onCancel={handleCancelCreate}
      />
      <style jsx>{``}</style>
    </>
  );
}

export default ListUpgPrayerAdoptionsPage;
