import React, { useCallback } from 'react';
import YesNoModal from '../../../../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { updateHolisticProjectPhotosMutation } from '../../../constants';
import { updateHolisticProjectAction } from '../../../../../redux-store/holistic-projects-store';

function DeletePhotoModal({
  holisticProject,
  photo,
  setPhoto,
  onComplete,
  queryId,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updateHolisticProject] = useMutation(
    updateHolisticProjectPhotosMutation,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await updateHolisticProject({
        variables: {
          holisticProject: {
            _id: holisticProject._id,
            photoIds: holisticProject.photoIds.filter((i) => i !== photo._id),
            primaryPhotoId:
              holisticProject.primaryPhotoId === photo._id ? null : undefined,
          },
        },
      });
      if (!response || !response.data || !response.data.updateHolisticProject) {
        throw new Error('Failed to update holistic project');
      }

      dispatch(
        updateHolisticProjectAction(
          response.data.updateHolisticProject.holisticProject,
          queryId,
        ),
      );

      notification.success({
        message: 'Deleted',
        description: 'Photo deleted successfully',
      });
      setTimeout(() => {
        setPhoto(null);
        if (onComplete) {
          onComplete();
        } else {
          history.goBack();
        }
      }, 1000);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [
    photo,
    holisticProject,
    updateHolisticProject,
    setPhoto,
    dispatch,
    history,
    onComplete,
    queryId,
  ]);

  const onNo = useCallback(() => {
    if (!loading) {
      setPhoto(null);
    }
  }, [setPhoto, loading]);

  return (
    <YesNoModal
      title="Delete holisticProject"
      question={`Are you sure you want to delete this photo?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!photo}
      loading={loading}
      error={error}
    />
  );
}

export default DeletePhotoModal;
