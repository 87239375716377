import { Form, InputNumber } from 'antd';
import React, { useCallback, useEffect } from 'react';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';
import { getNumberValue } from '@aims/shared/shared/utils';

function HProjectChangeLoanTotalInner({
  holisticProject,
  saving,
  handleFinish,
  onCancel,
  title = 'Change Loan Total',
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (holisticProject) {
      const loanTotal = getNumberValue(holisticProject.loanTotal);
      form.setFieldsValue({
        loanTotal: Math.round(loanTotal * 100) / 100,
      });
    }
  }, [holisticProject, form]);
  const _handleFinish = useCallback(
    (_values) => {
      const { loanTotal, ...values } = _values;
      handleFinish({
        loanTotal,
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <TaskSomethingForm
      title={title}
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item
        label="Loan Total"
        name="loanTotal"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <InputNumber
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          disabled={saving}
          min={0}
          step={1}
          style={{ width: '100%' }}
        />
      </Form.Item>
    </TaskSomethingForm>
  );
}

export default HProjectChangeLoanTotalInner;
