import sharedSettings from '@aims/shared/sharedSettings';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Empty, Form, Table, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import DisplayAdoptionA10 from './DisplayAdoptionA10';
import DisplayPaymentA10 from './DisplayPaymentA10';
import DisplayRecurringPaymentA10 from './DisplayRecurringPaymentA10';

const { Text, Title } = Typography;

function AdmRecurringQuestionPageA10({
  setPage,
  payment,
  recurringPayment,
  adoptionItems,
  onRemoveAdoption,
}) {
  const [formValues, setFormValues] = useState({});
  useEffect(() => {
    const values = {};
    if (adoptionItems) {
      adoptionItems.forEach((a) => {
        values[a.adoption._id] = {
          adoptionId: a.adoption._id,
          percent: (a.percent * 100).toFixed(1),
          rawPercent: a.percent,
          donation: (a.usdCentsAmount / 100).toFixed(2),
        };
      });
    }
    setFormValues(values);
  }, [adoptionItems]);

  const onNo = useCallback(() => {
    setSaving(true);
    adoptionItems.forEach((item) => {
      onRemoveAdoption(item);
    });
    setSaving(false);
    setPage('sponsorship');
  }, [adoptionItems, onRemoveAdoption, setPage]);

  const [saving, setSaving] = useState(false);
  const handleFinish = useCallback(() => {
    setPage('summary');
  }, [setPage]);

  const title = 'Sponsorships';

  const [form] = Form.useForm();

  return (
    <>
      <Title level={3} style={{ marginBottom: 0 }}>
        Associate Donation with Sponsorship(s)
      </Title>
      <Title
        level={5}
        style={{
          marginBottom: 4,
          marginTop: 0,
          color: sharedSettings.colors.text,
        }}
      >
        {recurringPayment
          ? 'Recurring Donation Association'
          : 'Suggested sponsorship(s)'}
      </Title>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: -16,
          marginLeft: -16,
          marginBottom: 4,
        }}
      >
        <Button
          key="back"
          type="text"
          onClick={() => setPage('contact')}
          icon={<ArrowLeftOutlined />}
        >
          Back
        </Button>
      </div>
      <div style={{ textAlign: 'center', marginBottom: 16 }}>
        {payment.suggestedAllocations?.length > 0 ? (
          <Text style={{ fontSize: 16 }}>
            {`This donation has the following suggested sponsorship(s).`}
          </Text>
        ) : (
          <Text style={{ fontSize: 16 }}>
            {`This donation is connected to the following recurring donation, and the associated recurring donation has already been allocated to the below sponsorship(s).`}
          </Text>
        )}
      </div>
      <DisplayPaymentA10 payment={payment} />
      {recurringPayment && (
        <DisplayRecurringPaymentA10 payment={recurringPayment} />
      )}
      <div style={{ textAlign: 'center', marginTop: 16, marginBottom: 0 }}>
        <Text style={{ fontSize: 16 }}>Please review</Text>
      </div>
      <Title level={4} style={{ marginTop: 0 }}>
        {title}
      </Title>
      <Table
        dataSource={adoptionItems}
        pagination={false}
        style={{ width: '100%' }}
        rowKey="_id"
        bordered
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No associated sponsorships"
            />
          ),
        }}
      >
        <Table.Column
          title="Sponsorship"
          dataIndex="givenOn"
          render={(text, record) => (
            <DisplayAdoptionA10 record={record.adoption} />
          )}
        />
        <Table.Column
          title="Percent"
          dataIndex="percent"
          width={140}
          render={(text, record) => {
            return (
              formValues[record._id] && `${formValues[record._id].percent}%`
            );
          }}
        />
        <Table.Column
          title="Amount"
          dataIndex="donation"
          width={160}
          render={(text, record) => {
            return (
              formValues[record._id] && `${formValues[record._id].donation}`
            );
          }}
        />
      </Table>
      <Form layout="vertical" form={form} style={{}} onFinish={handleFinish}>
        <div style={{ textAlign: 'center', marginTop: 16, marginBottom: 32 }}>
          {recurringPayment ? (
            <Text style={{ fontSize: 16 }}>
              {`Would you like to match this donation's associated sponsorships with its recurring donation's associated sponsorships?`}
            </Text>
          ) : (
            <Text style={{ fontSize: 16 }}>
              {`Would you like to match this donation's associated sponsorships with its suggested sponsorships?`}
            </Text>
          )}
        </div>
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{ width: 100, marginRight: 16 }}
              disabled={saving}
              onClick={onNo}
            >
              No
            </Button>
            <Button
              style={{ width: 100 }}
              loading={saving}
              type="primary"
              htmlType="submit"
            >
              Yes
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

export default AdmRecurringQuestionPageA10;
