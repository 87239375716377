import { Form, Typography } from 'antd';
import React from 'react';
import SaveSomethingButtons from '../../../components/SaveSomethingButtons';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';

const { Title, Text } = Typography;

function TaskSomethingForm({
  title,
  form,
  onFinish,
  onCancel,
  saving,
  children,
  formStyle,
  saveText,
}) {
  return (
    <>
      {title && (
        <Title level={3} style={{ marginTop: 0 }}>
          {title}
        </Title>
      )}
      <Form layout="vertical" form={form} onFinish={onFinish} style={formStyle}>
        {children}
        <ShowErrorsFormItem />
        <Form.Item>
          <SaveSomethingButtons
            onCancel={onCancel}
            saving={saving}
            saveText={saveText}
          />
        </Form.Item>
      </Form>
    </>
  );
}

export default TaskSomethingForm;
