import { HolisticProjectStages } from '@aims/shared/holistic-projects/constants';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, notification, Select, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { updateHolisticProjectMutation } from '../../../constants';

const { Paragraph, Title } = Typography;

function ChangeProjectStageModal({ project, showingModal, setShowingModal }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [updateHolisticProject] = useMutation(updateHolisticProjectMutation);
  const [form] = Form.useForm();

  const handleFinish = useCallback(
    async (values) => {
      setError(undefined);
      setLoading(true);
      try {
        await updateHolisticProject({
          variables: {
            holisticProject: {
              _id: project._id,
              stage: values.stage,
            },
          },
        });
        notification.success({
          message: 'Success',
          description: 'Holistic project accounting initiated',
        });
        setShowingModal(false);
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred',
        });
      }
      setLoading(false);
    },
    [updateHolisticProject, project, setShowingModal],
  );

  useEffect(() => {
    if (project) {
      form.setFieldsValue({
        stage: project.stage,
      });
    }
  }, [project, form]);

  return (
    <Modal
      header={null}
      footer={null}
      open={showingModal}
      closable={!loading}
      destroyOnClose={true}
      maskClosable={!loading}
      onCancel={() => {
        setShowingModal(false);
      }}
      styles={{ body: { paddingTop: 48 } }}
      width={400}
    >
      <Title level={3} style={{ textAlign: 'center', marginBottom: 16 }}>
        Change Project Stage
      </Title>
      <Form onFinish={handleFinish} layout="vertical" form={form}>
        <Form.Item
          label="Project Stage"
          name="stage"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Select style={{ width: '100%' }} disabled={loading}>
            {Object.entries(HolisticProjectStages).map(([key, obj]) => (
              <Select.Option key={obj.key} value={obj.key}>
                {obj.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <div
            style={{
              marginRight: -8,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => setShowingModal(null)}
              htmlType="button"
              disabled={loading}
              style={{
                marginLeft: 8,
                marginRight: 8,
                marginBottom: 8,
                minWidth: 100,
              }}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
              style={{
                marginLeft: 8,
                marginRight: 8,
                marginBottom: 8,
                minWidth: 100,
              }}
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
      {error && (
        <Paragraph type="danger" style={{ textAlign: 'center', marginTop: 16 }}>
          {error}
        </Paragraph>
      )}
    </Modal>
  );
}

export default ChangeProjectStageModal;
