import React, { useCallback } from 'react';
import ProjectAdminNotes from '../../ProjectAdminNotes';
import CumulativeProjectStats from './CumulativeProjectStats';
import HProjectDetailsCard from './HProjectDetailsCard';
import AnyHistory from '../../../../../components/AnyHistory';

function HolisticProjectDetailsTab({ project }) {
  const historyFilterFunc = useCallback(
    (a) => {
      let ret = true;
      if (a.holisticProjectId !== project._id) {
        ret = false;
      }
      return ret;
    },
    [project],
  );
  return (
    <>
      <HProjectDetailsCard holisticProject={project} />
      <CumulativeProjectStats holisticProject={project} />
      <ProjectAdminNotes project={project} />
      <AnyHistory
        filterFunc={historyFilterFunc}
        filters={{ holisticProjectId: project?._id }}
        title="Change History"
      />
    </>
  );
}

export default HolisticProjectDetailsTab;
