import { displayMoney } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { BarChartOutlined, ExportOutlined } from '@ant-design/icons';
import { gql, useApolloClient } from '@apollo/client';
import { Button, Form, Table, Typography, message } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import DateFormItem from '@aims/shared/components/DateFormItem';
import settings from '../../../settings';
import { exportTransfersToCsv } from './components/ExportTransfersBtn';
import { TransferTypes } from './constants';

const { Title, Text, Paragraph } = Typography;

export const reportingTransfersQuery = gql`
  query ReportingTransfersQuery(
    $first: Int
    $after: String
    $filters: TransferFilters
  ) {
    allTransfers(first: $first, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          description
          pending
          amount
          fromAccountId
          fromAccount {
            _id
            name
            description
            type
          }
          toAccountId
          toAccount {
            _id
            name
            description
            type
          }
          toContactId
          type
          allocationId
          adoptionId
          originalDonationId
          payoutId
          originalDate
          createdBy
          updatedBy
          createdAt
          updatedAt
        }
      }
    }
  }
`;

async function doTransfersQuery({ apolloClient, from, until, accountId }) {
  let hasNextPage = true;
  let after;
  let i = 0;
  let results = [];
  while (hasNextPage && i < settings.pageSize) {
    const result = await apolloClient.query({
      query: reportingTransfersQuery,
      variables: {
        first: settings.querySize,
        after,
        filters: {
          from,
          until,
          accountId,
          pending: false,
        },
      },
      fetchPolicy: 'network-only',
    });
    if (result?.data?.allTransfers?.edges) {
      hasNextPage = result.data.allTransfers.pageInfo.hasNextPage;
      after = result.data.allTransfers.pageInfo.endCursor;
      results = results.concat(
        result.data.allTransfers.edges.map((edge) => edge.node),
      );
    } else {
      break;
    }
    i += 1;
  }
  return results;
}

function ReportingTab({ selected }) {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [form] = Form.useForm();
  const [report, setReport] = useState();
  const apolloClient = useApolloClient();

  const handleFinish = useCallback(
    async (values) => {
      setLoading(true);
      setError(undefined);
      const { until, from } = values;
      try {
        if (selected.length === 0) {
          throw Error('At least one account must be selected.');
        }
        if (from > until) {
          throw Error(
            'Please select an end date that is after the start date.',
          );
        }
        const beforeDate = new Date(until);
        beforeDate.setHours(0);
        beforeDate.setMinutes(0);
        beforeDate.setSeconds(0);
        beforeDate.setMilliseconds(0);
        const onOrAfterDate = new Date(from);
        onOrAfterDate.setHours(0);
        onOrAfterDate.setMinutes(0);
        onOrAfterDate.setSeconds(0);
        onOrAfterDate.setMilliseconds(0);
        const results = await Promise.all(
          selected.map(
            async (account) =>
              await doTransfersQuery({
                apolloClient,
                from: onOrAfterDate.toISOString(),
                until: beforeDate.toISOString(),
                accountId: account._id,
              }),
          ),
        );
        const transferIds = new Set();
        const transfers = [];
        results.forEach((result) => {
          result.forEach((t) => {
            if (!transferIds.has(t._id)) {
              transferIds.add(t._id);
              transfers.push(t);
            }
          });
        });
        const accountIds = selected.map((account) => account._id);
        const totalIn = transfers.reduce((prev, curr) => {
          if (
            accountIds.includes(curr.toAccountId) &&
            (!curr.fromAccountId || !accountIds.includes(curr.fromAccountId))
          ) {
            return prev + curr.amount;
          }
          return prev;
        }, 0.0);
        const totalOut = transfers.reduce((prev, curr) => {
          if (
            curr.fromAccountId &&
            accountIds.includes(curr.fromAccountId) &&
            (!curr.toAccountId || !accountIds.includes(curr.toAccountId))
          ) {
            return prev + curr.amount;
          }
          return prev;
        }, 0.0);
        const totalBetween = transfers.reduce((prev, curr) => {
          if (
            curr.fromAccountId &&
            accountIds.includes(curr.fromAccountId) &&
            curr.toAccountId &&
            accountIds.includes(curr.toAccountId)
          ) {
            return prev + curr.amount;
          }
          return prev;
        }, 0.0);
        setReport({
          transfers: transfers.sort((a, b) =>
            a.originalDate.localeCompare(b.originalDate),
          ),
          totalIn,
          totalOut,
          totalBetween,
          from: onOrAfterDate,
          until: beforeDate,
        });
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [selected, apolloClient],
  );

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params.pagination,
    });
  }, []);
  const locale = useSelector((store) => store.locale, shallowEqual);
  const [exporting, setExporting] = useState(false);
  const [exportError, setExportError] = useState();
  const handleExportCsv = useCallback(() => {
    if (report) {
      message.info('Exporting ...');
      setExportError(null);
      setExporting(true);
      try {
        const title = `Transfers from ${new Intl.DateTimeFormat(locale, {
          dateStyle: 'medium',
        }).format(new Date(report.from))} until ${new Intl.DateTimeFormat(
          locale,
          {
            dateStyle: 'medium',
          },
        ).format(new Date(report.until))}`.replace(',', ' ');
        exportTransfersToCsv(report.transfers, locale, title);
        message.success('Success');
      } catch (err) {
        console.error(err);
        setExportError(err.message);
      }
      setExporting(false);
    }
  }, [locale, report]);

  const dateFormat = useMemo(
    () =>
      new Intl.DateTimeFormat(locale, {
        dateStyle: 'full',
      }),
    [locale],
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          backgroundColor: '#c6cfd3',
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 12,
          paddingBottom: 12,
          alignItems: 'center',
          borderRadius: 8,
          marginBottom: 32,
        }}
      >
        <Title level={5} style={{ margin: 8 }}>
          {`${selected.length} Accounts Selected`}
        </Title>
      </div>
      <div className="top-actions">
        <Form
          layout="vertical"
          form={form}
          onFinish={handleFinish}
          style={{ marginBottom: 16 }}
        >
          <div style={{ display: 'flex' }}>
            <DateFormItem
              name="from"
              label="From"
              rules={[{ required: true, message: 'This field is required' }]}
              disabled={loading}
              style={{ marginRight: 16, width: 200 }}
            />
            <DateFormItem
              name="until"
              label="Until"
              rules={[{ required: true, message: 'This field is required' }]}
              disabled={loading}
              style={{ width: 200 }}
            />
          </div>
          <Form.Item>
            <Button
              icon={<BarChartOutlined />}
              type="primary"
              shape="round"
              style={{ marginLeft: 'auto' }}
              htmlType="submit"
              loading={loading}
              disabled={!selected?.length}
            >
              Generate Report
            </Button>
          </Form.Item>
        </Form>
      </div>
      {error && (
        <Paragraph
          type="danger"
          style={{ padding: 32, textAlign: 'center', fontSize: 14 }}
        >
          {error}
        </Paragraph>
      )}
      {!error && !report && (
        <div
          style={{
            padding: 32,
            textAlign: 'center',
            fontSize: 72,
            fontWeight: 600,
            color: sharedSettings.colors.borderGray,
          }}
        >
          Get Ready
        </div>
      )}
      {!error && report && (
        <>
          <Title level={2}>Report</Title>
          <Paragraph
            style={{ textAlign: 'center', fontSize: 16 }}
          >{`From on or until ${new Intl.DateTimeFormat(locale, {
            dateStyle: 'full',
          }).format(
            new Date(report.from),
          )} until until ${new Intl.DateTimeFormat(locale, {
            dateStyle: 'full',
          }).format(new Date(report.until))}`}</Paragraph>
          <div className="totals-container">
            <div className="totals-section">
              <div>
                <Text
                  style={{
                    fontSize: 14,
                    color: sharedSettings.colors.textGray,
                  }}
                >
                  Total in
                </Text>
              </div>
              <Title level={3}>
                {report.totalIn != null && displayMoney(report.totalIn / 10000)}
              </Title>
            </div>
            <div className="totals-section">
              <div>
                <Text
                  style={{
                    fontSize: 18,
                    color: sharedSettings.colors.textGray,
                  }}
                >
                  Total between
                </Text>
              </div>
              <Title>
                {report.totalBetween != null &&
                  displayMoney(report.totalBetween / 10000)}
              </Title>
            </div>
            <div className="totals-section">
              <div>
                <Text
                  style={{
                    fontSize: 14,
                    color: sharedSettings.colors.textGray,
                  }}
                >
                  Total out
                </Text>
              </div>
              <Title level={3}>
                {report.totalOut != null &&
                  displayMoney(report.totalOut / 10000)}
              </Title>
            </div>
          </div>
          <Title level={2}>Transactions</Title>
          {exportError && (
            <Paragraph
              type="danger"
              style={{ padding: 16, textAlign: 'center', fontSize: 14 }}
            >
              {exportError}
            </Paragraph>
          )}
          <div style={{ marginBottom: 16, textAlign: 'right' }}>
            <Button
              icon={<ExportOutlined />}
              onClick={handleExportCsv}
              loading={exporting}
              shape="round"
            >
              Export CSV
            </Button>
          </div>
          <Table
            dataSource={report.transfers}
            pagination={{
              ...pagination,
              total: report.transfers && report.transfers.length,
            }}
            onChange={handleTableChange}
            style={{ width: '100%' }}
            scroll={{ x: 1200 }}
            rowKey="_id"
          >
            <Table.Column
              title="Description"
              dataIndex="description"
              key="description"
            />
            <Table.Column
              title="Transfer Type"
              dataIndex="type"
              render={(text, record) => {
                if (record.type) {
                  return (
                    TransferTypes[record.type] &&
                    TransferTypes[record.type].label
                  );
                }
                return null;
              }}
            />
            <Table.Column
              title="From"
              dataIndex="fromAccountId"
              render={(text, record) => {
                return record.fromAccount && record.fromAccount.name;
              }}
            />
            <Table.Column
              title="To"
              dataIndex="toAccountId"
              render={(text, record) => {
                return record.toAccount && record.toAccount.name;
              }}
            />
            <Table.Column
              title="Amount"
              dataIndex="amount"
              render={(text, record) => {
                if (record.amount) {
                  return displayMoney(record.amount / 10000);
                }
                return null;
              }}
            />
            <Table.Column
              title="Pending"
              dataIndex="pending"
              render={(text, record) => {
                return record.pending ? 'Yes' : 'No';
              }}
            />
            <Table.Column
              title="Original Date"
              dataIndex="originalDate"
              render={(text, record) => {
                return (
                  record.originalDate &&
                  dateFormat.format(new Date(record.originalDate))
                );
              }}
            />
            <Table.Column
              title="Created"
              dataIndex="createdAt"
              render={(text, record) => {
                return (
                  record.createdAt &&
                  dateFormat.format(new Date(record.createdAt))
                );
              }}
            />
          </Table>
        </>
      )}
      <style jsx>{`
        .totals-container {
          display: flex;
          margin-bottom: 16px;
          justify-content: center;
          padding-left: 10vw;
          padding-right: 10vw;
          align-items: center;
        }
        .totals-section {
          text-align: center;
          margin-left: 16px;
          margin-right: 16px;
          flex: 1;
        }
      `}</style>
    </>
  );
}

export default ReportingTab;
