import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import sharedSettings from '@aims/shared/sharedSettings';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Empty, List, Tooltip, Typography, notification } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { updateHolisticProjectAction } from '../../../../../redux-store/holistic-projects-store';
import {
  extractHolisticProjectForAdmin,
  holisticProjectPhotosQuery,
  updateHolisticProjectPhotosMutation,
} from '../../../constants';
import DeletePhotoModal from './DeletePhotoModal';
import UploadPhotoButton from './UploadPhotoButton';

const { Text } = Typography;

function PhotoCard({
  photo,
  makePrimary,
  setDeleting,
  loading,
  holisticProject,
}) {
  return (
    <div>
      <div className="actions">
        {!photo.isJp ? (
          <Tooltip title="Delete Photo">
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => setDeleting(photo)}
              style={{ marginRight: -8, marginBottom: 0 }}
            />
          </Tooltip>
        ) : (
          <div style={{ height: 48 }} />
        )}
      </div>
      <div className="container">
        <div className="card-default">
          <div className="card-default-letter">
            {holisticProject.projectName &&
              holisticProject.projectName[0].toUpperCase()}
          </div>
        </div>
        <div
          className="card-image"
          style={{ backgroundImage: `url("${photo.photoUrl}")` }}
        />
      </div>
      <div className="primary">
        {photo.isPrimary ? (
          <Text style={{ color: sharedSettings.colors.success }}>
            <CheckOutlined style={{ marginRight: 8 }} />
            Primary
          </Text>
        ) : (
          <Button
            loading={loading}
            onClick={() => {
              makePrimary(photo._id);
            }}
          >
            Make Primary
          </Button>
        )}
      </div>
      <style jsx>{`
        .actions {
          display: flex;
          justify-content: flex-end;
        }
        .container {
          display: flex;
          justify-content: center;
          position: relative;
        }
        .card-default {
          padding-bottom: 100%;
          width: 100%;
          background-position: top;
          background-size: cover;
          border-radius: 4px;
          overflow: hidden;
          background-color: ${sharedSettings.colors.darkGray};
          display: flex;
          justify-content: center;
        }
        .card-default-letter {
          height: 100%;
          display: flex;
          position: absolute;
          align-items: center;
          justify-content: center;
          font-size: 36px;
          color: white;
        }
        .card-image {
          padding-bottom: 100%;
          width: 100%;
          background-position: top;
          background-size: cover;
          border-radius: 4px;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
        }
        .primary {
          display: flex;
          justify-content: center;
          margin-top: 16px;
        }
      `}</style>
    </div>
  );
}

const queryId = 'holisticProjectPhotos';

function HolisticProjectPhotos({ projectId }) {
  const [deleting, setDeleting] = useState(null);

  const { loading, refetch } = useOneM1({
    variables: { _id: projectId },
    query: holisticProjectPhotosQuery,
    extract: extractHolisticProjectForAdmin,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateHolisticProjectAction,
    skip: !projectId,
    showError: false,
  });
  const holisticProject = useSingleSomethingA10(
    'holisticProjects',
    projectId,
    queryId,
  );

  const photos = useMemo(() => {
    if (holisticProject) {
      const _photos = [];
      if (holisticProject.photos) {
        holisticProject.photos.forEach((p) => {
          const photo = p.images && p.images.find((i) => i.sizeCode === 'SM');
          let url = photo && photo.url;
          if (!url) {
            url = p && p.thumbnail && p.thumbnail.url;
          }
          if (!url) {
            url = p && p.url;
          }
          _photos.push({
            _id: p._id,
            isPrimary: holisticProject.primaryPhotoId === p._id,
            photoUrl: url,
            name: holisticProject.projectName,
          });
        });
      }
      return _photos;
    }
    return [];
  }, [holisticProject]);

  const [updateHolisticProject] = useMutation(
    updateHolisticProjectPhotosMutation,
  );
  const [savingPrimary, setSavingPrimary] = useState({});

  const handleMakePrimary = useCallback(
    async (photoId) => {
      setSavingPrimary({ ...loading, [photoId]: true });
      try {
        await updateHolisticProject({
          variables: {
            holisticProject: {
              _id: holisticProject._id,
              primaryPhotoId: photoId,
            },
          },
        });
        refetch();
        notification.success({
          message: 'Saved',
          description: 'Photo uploaded successfully',
        });
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your photo',
        });
      }
      setSavingPrimary({ ...loading, [photoId]: false });
    },
    [updateHolisticProject, loading, holisticProject, refetch],
  );

  return (
    <div className="container">
      <List
        dataSource={photos}
        renderItem={(item) => (
          <List.Item>
            <PhotoCard
              photo={item}
              makePrimary={handleMakePrimary}
              loading={savingPrimary[item._id]}
              setDeleting={setDeleting}
              holisticProject={holisticProject}
            />
          </List.Item>
        )}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Photos"
            />
          ),
        }}
        grid={{ xs: 1, sm: 1, md: 2, lg: 4, xl: 5, xxl: 5, gutter: 16 }}
      />
      <div className="top-actions">
        <UploadPhotoButton
          holisticProject={holisticProject}
          refetch={refetch}
        />
      </div>
      <div style={{ minHeight: 300 }} />
      <DeletePhotoModal
        holisticProject={holisticProject}
        setPhoto={setDeleting}
        photo={deleting}
        queryId={queryId}
        onComplete={() => {}}
      />
      <style jsx>{`
        .top-actions {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </div>
  );
}

export default HolisticProjectPhotos;
