import { gql } from '@apollo/client';

const holisticProjectAccountingAttrs = gql`
  {
    _id
    projectNo
    loanTotal
    fundingAccountId
    stage
    fundingAccount {
      _id
      name
      description
      fundId
      fund {
        _id
        name
      }
      type
      startingBalance
      external
      balance {
        _id
        balance
        incoming
        outgoing
        updatedAt
      }
      createdAt
      updatedAt
    }
    payoutAccountId
    payoutAccount {
      _id
      name
      description
      fundId
      fund {
        _id
        name
      }
      type
      startingBalance
      external
      balance {
        _id
        balance
        incoming
        outgoing
        updatedAt
      }
      createdAt
      updatedAt
    }
    paybackAccountId
    paybackAccount {
      _id
      name
      description
      fundId
      fund {
        _id
        name
      }
      type
      startingBalance
      external
      balance {
        _id
        balance
        incoming
        outgoing
        updatedAt
      }
      createdAt
      updatedAt
    }
    fundingTarget
    fundingBalance
    fundingPercentage
    paybackBalance
    paybackPercentage
    showInCatalog
  }
`;

export const holisticProjectAccountingQuery = gql`
  query HolisticProjectAccounting($_id: ID!) {
    holisticProjectForAdmin(_id: $_id) ${holisticProjectAccountingAttrs}
  }
`;

export const initiateHolisticProjectAccountingMutation = gql`
  mutation InitiateHolisticProjectAccounting(
    $_id: ID!,
    $projectNo: Int
  ) {
    initiateHolisticProjectAccounting(_id: $_id, projectNo: $projectNo) {
      holisticProject ${holisticProjectAccountingAttrs}
    }
  }
`;

export const holisticProjectAccountingUpdatesSubscription = gql`
  subscription HolisticProjectAccountingUpdates($_id: ID!) {
    holisticProjectUpdatesForAdmin(_id: $_id) {
      crud
      id
      new ${holisticProjectAccountingAttrs}
    }
  }
`;
