import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tooltip } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import settings from '../../../settings';
import { AccountTypesJ25 } from './constants';

function AccountsTable({ accounts, loading, selected, setSelected }) {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params.pagination,
    });
  }, []);
  const locale = useSelector((store) => store.locale, shallowEqual);
  const dateFormat = useMemo(
    () =>
      new Intl.DateTimeFormat(locale, {
        dateStyle: 'full',
      }),
    [locale],
  );
  return (
    <Table
      dataSource={accounts}
      loading={!accounts.length && loading}
      pagination={{ ...pagination, total: accounts.length }}
      onChange={handleTableChange}
      style={{ width: '100%' }}
      rowKey="_id"
      scroll={{ x: 1200 }}
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys: selected.map((r) => r._id),
        onChange: (selectedRowKeys, selectedRows) => {
          setSelected(selectedRows);
        },
        getCheckboxProps: (record) => ({
          disabled: false,
          name: record._id,
        }),
      }}
    >
      <Table.Column
        title="Account Name"
        dataIndex="name"
        key="name"
        width={240}
      />
      <Table.Column
        title="Fund"
        dataIndex="fund"
        render={(text, record) => record.fund?.name}
        width={240}
      />
      <Table.Column
        title="Account Type"
        dataIndex="type"
        render={(text, record) => AccountTypesJ25[record.type]?.label}
        width={240}
      />
      <Table.Column
        title="Balance"
        dataIndex="balance"
        render={(text, record) => {
          if (record.balance) {
            return displayMoney(
              getNumberValue(record.balance?.balance) / 10000,
            );
          }
          return null;
        }}
      />
      <Table.Column
        title="Updated"
        dataIndex="updatedAt"
        render={(text, record) => {
          if (record.balance) {
            return dateFormat.format(new Date(record.balance?.updatedAt));
          }
          return dateFormat.format(new Date(record.updatedAt));
        }}
      />
      <Table.Column
        title="Action"
        key="action"
        render={(text, record) => (
          <Space size="middle">
            <Tooltip title="View">
              <Link to={`/accounting/accounts/view/${record._id}`}>
                <Button icon={<EyeOutlined />} />
              </Link>
            </Tooltip>
          </Space>
        )}
      />
    </Table>
  );
}

export default AccountsTable;
