import { gql } from '@apollo/client';

export const AgreementStatuses = {
  PREPARING: {
    key: 'PREPARING',
    label: 'Preparing',
    color: 'warning',
  },
  SIGNING: {
    key: 'SIGNING',
    label: 'Signing',
    color: 'blue',
  },
  SIGNED: {
    key: 'SIGNED',
    label: 'Signed',
    color: 'success',
  },
  CANCELLED: {
    key: 'CANCELLED',
    label: 'Cancelled',
    color: 'red',
  },
  PREVIEW: {
    key: 'PREVIEW',
    label: 'Preview',
    color: 'warning',
  },
};

export const loanAgreementTemplateDetailedAttributes = gql`
  {
    _id
    agreementType
    status
    name
    isTemplate
    parties {
      _id
      ref
      name
      contactId
      contactName
      signature
      date
      fontName
    }
    signeeIds
    sections {
      _id
      title
      text
      initials {
        partyId
        initials
        date
      }
    }
    attachments {
      _id
      ref
      name
      fileId
      file {
        _id
        size
        filename
        contentType
        url
        uploaded
        processed
      }
    }
    projectId
    country
    amount
    terms
    paySchedule
    beneficiaries {
      contactId
      fullName
    }
    approvalDate
    finalizedDate
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const loanAgreementTemplateListAttributes = gql`
  {
    _id
    agreementType
    status
    name
    isTemplate
    sections {
      _id
      title
    }
    parties {
      _id
      ref
      name
    }
    attachments {
      _id
      ref
      name
      fileId
    }
    terms
    paySchedule
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const createLoanAgreementTemplateMutation = gql`
  mutation CreateLoanAgreementTemplate(
    $loanAgreement: LoanAgreementCreateInput!
  ) {
    createLoanAgreement(loanAgreement: $loanAgreement) {
      loanAgreement ${loanAgreementTemplateDetailedAttributes}
    }
  }
`;

export const updateLoanAgreementTemplateMutation = gql`
  mutation UpdateLoanAgreementTemplate(
    $loanAgreement: LoanAgreementUpdateInput!
  ) {
    updateLoanAgreement(loanAgreement: $loanAgreement) {
      loanAgreement ${loanAgreementTemplateDetailedAttributes}
    }
  }
`;

export const allLoanAgreementTemplatesQuery = gql`
  query AllLoanAgreementTemplates(
    $first: Int
    $after: String
    $filters: LoanAgreementFiltersForAdmin
  ) {
    allLoanAgreementsForAdmin(first: $first, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${loanAgreementTemplateListAttributes}
      }
    }
  }
`;

export const extractLoanAgreements = (data) => data.allLoanAgreementsForAdmin;

export const loanAgreementTemplateQuery = gql`
  query LoanAgreementTemplateForAdmin($_id: ID!) {
    loanAgreementForAdmin(_id: $_id) ${loanAgreementTemplateDetailedAttributes}
  }
`;

export const extractLoanAgreement = (data) =>
  data && data.loanAgreementForAdmin;

export const deleteLoanAgreementMutation = gql`
  mutation DeleteLoanAgreement($_id: ID!) {
    deleteLoanAgreement(_id: $_id) {
      deletedId
    }
  }
`;

export const projectLoanAgreementListAttributes = gql`
  {
    _id
    agreementType
    status
    version
    name
    isTemplate
    projectId
    sections {
      _id
      title
    }
    parties {
      _id
      ref
      name
      date
      contactName
    }
    attachments {
      _id
      ref
      name
      fileId
    }
    terms
    paySchedule
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const allProjectLoanAgreementQuery = gql`
  query AllProjectLoanAgreementForAdmin(
    $first: Int
    $after: String
    $filters: LoanAgreementFiltersForAdmin
    $sortBy: [SortBy]
  ) {
    allLoanAgreementsForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${projectLoanAgreementListAttributes}
      }
    }
  }
`;

export const projectLoanAgreementDetailedAttributes = gql`
  {
    _id
    agreementType
    status
    version
    name
    isTemplate
    projectId
    project {
      _id
      peopleGroupIds
      projectName
      typeOfProject
      desc
      status
      loanTotal
      loanTerm
      loanStartDate
      supportedFieldWorkers {
        contactId
        contact {
          _id
          fullName
        }
      }
    }
    parties {
      _id
      ref
      name
      contactId
      contactName
      signature
      date
      fontName
    }
    signeeIds
    sections {
      _id
      title
      text
      initials {
        partyId
        initials
        fontName
        date
      }
    }
    attachments {
      _id
      ref
      name
      fileId
      file {
        _id
        size
        filename
        contentType
        url
        uploaded
        processed
      }
    }
    projectId
    country
    countryName
    amount
    terms
    paySchedule
    beneficiaries {
      contactId
      fullName
    }
    approvalDate
    finalizedDate
    privateNotes
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const createLoanAgreementFromTemplateMutation = gql`
  mutation CreateLoanAgreementFromTemplate(
    $templateId: ID!, $projectId: ID!, $newId: ID!, $versionNo: Int!,
  ) {
    createLoanAgreementFromTemplate(templateId: $templateId, projectId: $projectId, newId: $newId, versionNo: $versionNo) {
      loanAgreement ${projectLoanAgreementDetailedAttributes}
    }
  }
`;

export const projectLoanAgreementQuery = gql`
  query ProjectLoanAgreement($_id: ID!) {
    loanAgreementForAdmin(_id: $_id) ${projectLoanAgreementDetailedAttributes}
  }
`;

export const updateLoanAgreementMutation = gql`
  mutation UpdateLoanAgreement(
    $loanAgreement: LoanAgreementUpdateInput!
  ) {
    updateLoanAgreement(loanAgreement: $loanAgreement) {
      loanAgreement ${projectLoanAgreementDetailedAttributes}
    }
  }
`;

export const previewLoanAgreementMutation = gql`
  mutation PreviewLoanAgreement(
    $loanAgreementId: ID!
  ) {
    previewLoanAgreement(loanAgreementId: $loanAgreementId) {
      loanAgreement ${projectLoanAgreementDetailedAttributes}
    }
  }
`;

export const prepareLoanAgreementMutation = gql`
  mutation PrepareLoanAgreement(
    $loanAgreementId: ID!
  ) {
    prepareLoanAgreement(loanAgreementId: $loanAgreementId) {
      loanAgreement ${projectLoanAgreementDetailedAttributes}
    }
  }
`;

export const cancelLoanAgreementMutation = gql`
  mutation CancelLoanAgreement(
    $loanAgreementId: ID!
  ) {
    cancelLoanAgreement(loanAgreementId: $loanAgreementId) {
      loanAgreement ${projectLoanAgreementDetailedAttributes}
    }
  }
`;

export const sendLoanAgreementSignatureReminderMutation = gql`
  mutation SendLoanAgreementSignatureReminder($loanAgreementId: ID!) {
    sendLoanAgreementSignatureReminder(loanAgreementId: $loanAgreementId)
  }
`;

export const sendLoanAgreementMutation = gql`
  mutation SendLoanAgreement(
    $loanAgreementId: ID!
  ) {
    sendLoanAgreement(loanAgreementId: $loanAgreementId) {
      loanAgreement ${projectLoanAgreementDetailedAttributes}
    }
  }
`;

export const signLoanAgreementMutation = gql`
  mutation SignLoanAgreement(
    $signature: LoanAgreementSignInput!
  ) {
    signLoanAgreement(signature: $signature) {
      loanAgreement ${projectLoanAgreementDetailedAttributes}
    }
  }
`;

export const initialLoanAgreementSectionMutation = gql`
  mutation InitialLoanAgreementSection(
    $initials: LoanAgreementInitialsInput!
  ) {
    initialLoanAgreementSection(initials: $initials) {
      loanAgreement ${projectLoanAgreementDetailedAttributes}
    }
  }
`;
